import React, { useState }from 'react';
import { Container, Typography, Grid, Link, Box, Popover } from '@mui/material';
import './Footer.css';
import ContactDialog from './ContactComponent';
import TermsDialog from './TermsDialog';

function Footer() {
  const [termsOpen, setTermsOpen] = useState(false);

  const [contactOpen, setContactOpen] = useState(false);

  const handleTermsOpen = () => setTermsOpen(true);
  const handleTermsClose = () => setTermsOpen(false);

  // Handlers for contact dialog
  const handleContactOpen = () => setContactOpen(true);
  const handleContactClose = () => setContactOpen(false);

    return (
        <footer className='footer'>
          <Container maxWidth="lg">
            <Box py={.2} /* Reduced padding */ textAlign="center" borderTop={1} borderColor="grey.200">
                  <Typography variant="caption" display="block" gutterBottom>
                        © 2024 Book-Up
                    </Typography>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={4}>
                        <Link
                            variant="caption"
                            onClick={handleContactOpen}
                            style={{ cursor: 'pointer' }}
                        >
                            Contact Us
                        </Link>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Link
                                variant="caption"
                                onClick={handleTermsOpen}
                                style={{ cursor: 'pointer' }}
                            >
                                Terms of Service
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Link variant="caption" href="/aboutus" style={{ cursor: 'pointer' }}>
                                About Us
                            </Link>
                        </Grid>
                    </Grid>
                    <Box mt={.2} />
                </Box>
            </Container>

            <TermsDialog 
                open={termsOpen}
                onClose={handleTermsClose}
            />
            <ContactDialog 
                open={contactOpen}
                onClose={handleContactClose}
            />
        </footer>
    );
}

export default Footer;