import React from 'react';
import {
    Dialog,
    DialogContent,
    Box,
    IconButton,
    Typography,
    Button
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const TermsDialog = ({ open, onClose }) => {
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            fullWidth
            maxWidth="md"
            TransitionProps={{
                timeout: 300
            }}
            PaperProps={{
                elevation: 0,
                sx: {
                    borderRadius: '24px',
                    background: 'linear-gradient(145deg, #fff 0%, #fcfaff 100%)',
                    backdropFilter: 'blur(20px)',
                    border: '1px solid rgba(239, 97, 145, 0.1)',
                    position: 'relative',
                    overflow: 'hidden',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '180px',
                        background: 'linear-gradient(145deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
                        zIndex: 0
                    }
                }
            }}
        >
            {/* Close Button */}
            <Box sx={{ 
                position: 'absolute', 
                top: 0, 
                right: 0,
                m: 2,
                zIndex: 1
            }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        color: '#EF6191',
                        backgroundColor: 'rgba(239, 97, 145, 0.08)',
                        backdropFilter: 'blur(8px)',
                        '&:hover': {
                            backgroundColor: 'rgba(239, 97, 145, 0.12)',
                            transform: 'scale(1.05)',
                        },
                        transition: 'all 0.2s ease-in-out'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            {/* Header Content */}
            <Box sx={{ px: 4, pt: 4, pb: 2, position: 'relative' }}>
                <Typography variant="h4" sx={{ 
                    fontWeight: 700,
                    background: 'linear-gradient(135deg, #EF6191 0%, #B366CC 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 1
                }}>
                    Terms of Service
                </Typography>
            </Box>

            {/* PDF Content */}
            <DialogContent 
                sx={{ 
                    px: 4,
                    pb: 4,
                    height: '70vh',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'rgba(239, 97, 145, 0.04)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'rgba(239, 97, 145, 0.2)',
                        borderRadius: '4px',
                        '&:hover': {
                            background: 'rgba(239, 97, 145, 0.3)',
                        },
                    },
                }}
            >
                <iframe
                     src="/Live Local Doc Terms.pdf"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        borderRadius: '12px',
                    }}
                    title="Terms of Service"
                />
            </DialogContent>

            {/* Footer */}
            <Box sx={{ 
                px: 4, 
                py: 3, 
                borderTop: '1px solid rgba(239, 97, 145, 0.08)',
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button 
                    onClick={onClose}
                    sx={{
                        borderRadius: '12px',
                        padding: '12px 24px',
                        color: '#EF6191',
                        backgroundColor: 'rgba(239, 97, 145, 0.04)',
                        '&:hover': {
                            backgroundColor: 'rgba(239, 97, 145, 0.08)',
                        },
                        transition: 'all 0.2s ease-in-out',
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 500
                    }}
                >
                    Close
                </Button>
            </Box>
        </Dialog>
    );
};

export default TermsDialog;