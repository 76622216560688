import React, { useState, useEffect } from 'react';
import './stripeCreate.css';
import API_URL from '../config/apiUrl';
function BankingProfileForm({ profileType }) {
    console.log(profileType)
    const [step, setStep] = useState(0); // State to track the current form step
    const [formData, setFormData] = useState({
        accountHolderName: '',
        accountNumber: '',
        routingNumber: '',
        termsAccepted: false,
        accountType: '',
        ssn: '',
        bin: '',
        line1: '',
        city: '',
        state: '',
        zip: '',
        companyName: '',
        fname: '',
        lname: '',
        website: '',
        dob: '',
        phone: '',
        bankAccountType: ''
    });
    const [stripeAccountNumber, setStripeAccountNumber] = useState('');
    const [accountType, setAccountType] = useState("individual");
    const [bankAccountType, setBankAccountType] = useState("individual");

    // Handle input changes
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const [ip, setIP] = useState("");

    function useFetchIP() {

        useEffect(() => {
            const fetchIP = async () => {
                try {
                    const response = await fetch("https://api.ipify.org?format=json");
                    const data = await response.json();
                    setIP(data.ip);
                } catch (error) {
                    console.error("Failed to fetch IP address:", error);
                }
            };

            fetchIP();
        }, []); // Empty dependency array ensures this runs only once on mount
    }


    useFetchIP();
    console.log("User's IP Address:", ip);

    // Handle first form submission
    const handleThirdFormSubmit = async (event) => {
        event.preventDefault();

        // Validate terms acceptance
        if (!formData.termsAccepted) {
            alert('You must agree to the terms of service.');
            return;
        }

        try {
            console.log(formData);
            let tos_acceptance_date = Math.floor(Date.now()/ 1000)
            const userAgent = navigator.userAgent;
            const response = await fetch(`${API_URL}/api/add-bank-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountHolderName: formData.accountHolderName,
                    accountNumber: formData.accountNumber,
                    routingNumber: formData.routingNumber,
                    stripeAccountNumber: stripeAccountNumber,
                    ipAddress: ip,
                    tos_acceptance_date: tos_acceptance_date,
                    profileType: profileType,
                    bankAccountType: bankAccountType,
                    userAgent: userAgent
                }),
                credentials: 'include'
            });

            const result = await response.json();


            if (response.ok) {
                alert('Bank details saved successfully!');
                window.location.reload();

            } else {
                alert('Failed to save bank details.');
                console.error('Error from backend:', result);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while saving bank details.');
        }
    };


    const handleFirstPageSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(formData);
            const response = await fetch(`${API_URL}/api/create-stripe-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accountType: accountType.toLowerCase(),
                    profileType: profileType,
                    ssn: formData.ssn,
                    bin: formData.bin,
                    line1: formData.line1,
                    city: formData.city,
                    state: formData.state,
                    zip: formData.zip,
                    firstName: formData.fname,
                    lastName: formData.lname,
                    companyName: formData.companyName,
                    website: formData.website,
                    dob: formData.dob,
                    phone: formData.phone,
                    ipAddress: ip
                }),
                credentials: 'include'
            });

            const result = await response.json();
            setStripeAccountNumber(result.id);

            if (response.ok) {
                console.log('Response from backend:', result);
                setStep(2);

            } else {
                alert('Failed to save bank details.');
                console.error('Error from backend:', result);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
        handleChange(event); // Update parent state if necessary
    };

    const handleBankAccountTypeChange = (event) => {
        setBankAccountType(event.target.value);
        handleChange(event); // Update parent state if necessary
    };


    const handleZeroPageSubmit = (event) => {
        setStep(1)
    }

    return (
        <div className="wrapper">
            {step === 0 && (
                <form onSubmit={handleZeroPageSubmit}>
                    <h2 style={{justifySelf: "center", color: "#09253f"}}>Disclaimer:</h2>
                    <p style={{fontSize: "14px"}}>
                        Our platform uses <strong style={{color: "#5433FF"}}>Stripe</strong> to handle all financial
                        transactions securely.
                        Stripe is a trusted, industry-leading payment processor known for its robust security and
                        compliance standards. Here's what you need to know:
                    </p>
                    <ul style={{fontSize: "14px", lineHeight: "1.5"}}>
                        <li>
                            <strong style={{color: "#5433FF"}}>We do not store sensitive information:</strong> All
                            account and payment details
                            you provide are securely tokenized and stored by Stripe. This ensures your data is
                            protected and cannot be accessed directly by us.
                        </li>
                        <li>
                            <strong style={{color: "#5433FF"}}>Why we use Stripe:</strong> Stripe's advanced security
                            measures, including
                            encryption and fraud prevention, help protect your information. Their compliance with
                            industry standards like PCI DSS provides you with peace of mind.
                        </li>
                        <li>
                            <strong style={{color: "#5433FF"}}>Why we ask for this information:</strong> To set up
                            seamless transactions,
                            Stripe requires certain details to validate your identity or business. This ensures
                            compliance with financial regulations and enables secure, direct deposits to your
                            account.
                        </li>
                    </ul>
                    <br/>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button type="submit" className="edit-button">Next</button>
                    </div>
                    <a
                        href="https://stripe.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        <img
                            src="/stripe.svg"
                            alt="Stripe Logo"
                            style={{width: "20%", verticalAlign: "bottom"}}
                        />
                    </a>

                </form>
            )}
            {step === 1 && profileType === 'performer' && (
                <form onSubmit={handleFirstPageSubmit}>
                    <h1>Billing Information</h1>
                    <div className="input-box">
                        <label>Account Type</label>
                        <select
                            name="accountType"
                            value={accountType}
                            onChange={handleAccountTypeChange}
                            required
                        >
                            <option value="company">Business</option>
                            <option value="individual">Individual</option>
                        </select>
                    </div>
                    {accountType === "individual" && (
                        <>
                            <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        name="fname"
                                        placeholder="John"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name="lname"
                                        placeholder="Doe"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <p style={{fontSize: "14px"}}>
                                To verify identity Stripe requires you to link a website, if you have a
                                dedicated page please provide a link. Otherwise a social media account related to your
                                business will work.&nbsp;
                                <span style={{fontWeight: "bold"}}>
                                     ex: www.facebook.com/TheLumineers
                                </span>
                            </p>
                            <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>Website</label>
                                    <input
                                        type="text"
                                        name="website"
                                        placeholder="www.thelumineers.com"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>Date of Birth</label>
                                    <input type={'date'} name="dob" onChange={handleChange} required/>
                                </div>
                            </div>
                        </>
                    )}
                    {accountType === "company" && (
                        <>
                            <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        name="companyName"
                                        placeholder="The Lumineers"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-box" style={{width: "35vw"}}>
                                    <label>Company Website</label>
                                    <input
                                        type="text"
                                        name="website"
                                        placeholder="www.google.com"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <>
                        <div className="input-box">
                            <label>Address Line</label>
                            <input
                                type="text"
                                name="line1"
                                placeholder="99 Salem ST"
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                            <div className="input-box" style={{width: "28vw"}}>
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="Boston"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-box" style={{width: "28vw"}}>
                                <label>State</label>
                                <input
                                    type="text"
                                    name="state"
                                    placeholder="MA"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-box" style={{width: "14vw"}}>
                                <label>Zip Code</label>
                                <input
                                    type="text"
                                    name="zip"
                                    placeholder="02113"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </>
                    {accountType === "company" && (
                        <div className="input-box">
                            <label>Employer Identification Number</label>
                            <input
                                type="text"
                                name="bin"
                                placeholder="12-3456789"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    )}
                    {accountType === "individual" && (
                        <div className="input-box">
                            <label>Social Security Number (SSN)</label>
                            <input
                                type="text"
                                name="ssn"
                                placeholder="123-45-6789"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    )}
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button type="submit" className="edit-button">Next</button>
                    </div>
                    <a
                        href="https://stripe.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        <img
                            src="/stripe.svg"
                            alt="Stripe Logo"
                            style={{width: "20%", verticalAlign: "bottom"}}
                        />
                    </a>
                </form>
                )}
            {step === 1 && profileType === 'vendor' && (
                <form onSubmit={handleFirstPageSubmit}>
                    <h1>Billing Information</h1>
                    <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                        <div className="input-box" style={{width: "35vw"}}>
                            <label>Company Name</label>
                            <input
                                type="text"
                                name="companyName"
                                placeholder="The Lumineers"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-box" style={{width: "35vw"}}>
                            <label>Phone Number for 2FA</label>
                            <input
                                type={"tel"}
                                name="phone"
                                placeholder="617-777-0985"
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                <>
                <div className="input-box">
                    <label>Address Line</label>
                    <input
                    type="text"
                    name="line1"
                    placeholder="99 Salem ST"
                    onChange={handleChange}
                    required
                    />
                </div>

    <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
        <div className="input-box" style={{width: "28vw"}}>
            <label>City</label>
            <input
                type="text"
                name="city"
                placeholder="Boston"
                onChange={handleChange}
                required
            />
        </div>
        <div className="input-box" style={{width: "28vw"}}>
            <label>State</label>
            <input
                type="text"
                name="state"
                placeholder="MA"
                onChange={handleChange}
                required
            />
        </div>
        <div className="input-box" style={{width: "14vw"}}>
            <label>Zip Code</label>
            <input
                type="text"
                name="zip"
                placeholder="02113"
                onChange={handleChange}
                required
            />
        </div>
    </div>
    </>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button type="submit" className="edit-button">Next</button>
                    </div>
                    <a
                        href="https://stripe.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        <img
                            src="/stripe.svg"
                            alt="Stripe Logo"
                            style={{width: "20%", verticalAlign: "bottom"}}
                        />
                    </a>
                </form>
            )}
            {step === 2 && (
                <form onSubmit={handleThirdFormSubmit}>
                    <h1>Banking Details</h1>
                    <div className="input-box">
                        <label>Account Holder Name</label>
                        <input
                            type="text"
                            name="accountHolderName"
                            placeholder="John Doe"
                            value={formData.accountHolderName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", gap: "10vw"}}>
                        <div className="input-box" style={{width: "35vw"}}>
                            <label>Bank Account Number</label>
                            <input
                                type="text"
                                name="accountNumber"
                                placeholder="000123456789"
                                value={formData.accountNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-box" style={{width: "35vw"}}>
                            <label>Routing Number</label>
                            <input
                                type="text"
                                name="routingNumber"
                                placeholder="110000000"
                                value={formData.routingNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {profileType === 'vendor' && (
                            <div className="input-box">
                                <label>Account Type</label>
                                <select
                                    name="bankAccountType"
                                    value={bankAccountType}
                                    onChange={handleBankAccountTypeChange}
                                    required
                                >
                                    <option value="individual">Individual</option>
                                    <option value="company">Company</option>
                                </select>
                            </div>
                        )}
                    </div>
                    <div className="input-box">
                        <label className="checkbox-item">
                            <input
                                type="checkbox"
                                name="termsAccepted"
                                checked={formData.termsAccepted}
                                onChange={handleChange}
                                required
                            />
                            <span style={{fontSize: '12px'}}>
                                Agree to{' '}
                                <a
                                    href="https://stripe.com/legal/connect-account"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    terms of service
                                </a>
                            </span>
                        </label>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <button type="submit" className="edit-button">Save Bank</button>
                    </div>
                    <a
                        href="https://stripe.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        <img
                            src="/stripe.svg"
                            alt="Stripe Logo"
                            style={{width: "20%", verticalAlign: "bottom"}}
                        />
                    </a>
                </form>
            )}
        </div>
    );
}

export default BankingProfileForm;
