import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext'; // Make sure this import is correct
import './Messages.css';
import API_URL from '../config/apiUrl';
const MessageContent = ({ content }) => {
    const navigate = useNavigate();  // Add this


    const processMessageContent = (text) => {
        if (text.includes("This is Booking Agent Sam with your weekly update!")) {
            const eventRegex = /(\d+\))\s*(.+)\s*\n(\d{2}\/\d{2}\/\d{2}) at (\d{1,2}:\d{2}(?:am|pm))\s*\n\$(\d+)\s*\n/g;
            const events = [];
            let match;

            // Extract each event using regex
            while ((match = eventRegex.exec(text)) !== null) {
                events.push({
                    eventId: match[1], // Event number
                    venue: match[2], // Venue name
                    date: match[3], // Date
                    time: match[4], // Time
                    amount: match[5], // Amount
                });
            }

            return (
                <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-200">
                    <div className="flex items-center mb-3">
                        <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                        <p className="text-lg font-semibold text-gray-800">
                            Hi there! This is Booking Agent Sam with your weekly update!
                            <br/>Here is what I found:
                        </p>
                    </div>
                    <div className="space-y-4 text-left">
                        {events.map((event, index) => (
                            <div key={index} className="p-2 border-b border-gray-200">
                                <div className="flex">
                                    <p style={{display: "block", margin: "6px"}}></p>
                                    <h5 style={{display: 'inline-block'}}>
                                        {event.eventId} {event.venue} • 73% match
                                    </h5>
                                    <br/>
                                    <h5 style={{display: 'inline-block', fontWeight:"normal"}}>
                                        {event.date} {event.time} • ${event.amount}
                                    </h5>
                                    <h5>
                                        <a href={"/"} style={{color:'#EF6191'}}>
                                            Click for more info
                                        </a>
                                    </h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
      if (text.includes('New bid received for your event!')) {
        // Extract all the bid details using regex
        const venueMatch = text.match(/Venue: ([^\n]+)/);
        const dateMatch = text.match(/Date: ([^\n]+)/);
        const timeMatch = text.match(/Time: ([^\n]+)/);
        const locationMatch = text.match(/Location: ([^\n]+)/);
        const bidAmountMatch = text.match(/Bid Amount: \$([^\n]+)/);
        const technicalMatch = text.match(/Technical Requirements: ([^\n]+)/);
        const artistNotesMatch = text.match(/Artist Notes: ([^\n]+)/);
        const contractMatch = text.match(/\/contracts\/(\d+)/);


          return (
          <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-200">
            <div className="flex items-center mb-3">
              <div className="w-2 h-2 bg-pink-500 rounded-full mr-2"></div>
              <h3 className="text-lg font-semibold text-gray-800">New Bid Received!</h3>
            </div>

            <div className="space-y-2 text-left">
              {venueMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Venue:</span>
                  <span className="text-gray-800">{venueMatch[1]}</span>
                </div>
              )}
              
              {dateMatch && timeMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Schedule:</span>
                  <span className="text-gray-800">
                    {dateMatch[1].split('GMT')[0]} • {timeMatch[1]}
                  </span>
                </div>
              )}
              
              {locationMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Location:</span>
                  <span className="text-gray-800">{locationMatch[1]}</span>
                </div>
              )}
              
              {bidAmountMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Bid Amount:</span>
                  <span className="text-gray-800 font-medium">${bidAmountMatch[1]}</span>
                </div>
              )}
              
              {technicalMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Technical:</span>
                  <span className="text-gray-800">{technicalMatch[1]}</span>
                </div>
              )}
              
              {artistNotesMatch && (
                <div className="flex">
                  <span className="text-gray-600 w-32">Notes:</span>
                  <span className="text-gray-800">{artistNotesMatch[1]}</span>
                </div>
              )}
  
            {contractMatch && (
                <div className="mt-4">
                    <button
                    onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/contracts/${contractMatch[1]}`);  // Use this instead of window.location.href
                    }}
                    className="w-full px-4 py-2 bg-pink-600 text-white rounded-md hover:bg-pink-700 transition-colors duration-200"
                >
                    View Contract Details
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      }

      const contractLinkRegex = /\/contracts\/(\d+)/g;
      const parts = text.split(contractLinkRegex);
      
      return parts.map((part, index) => {
        if (index % 2 === 0) {
          return part;
        } else {
          return (
            <button
              key={`contract-${part}`}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/contracts/${part}`);  // Use this instead of window.location.href
              }}
              className="inline-block px-4 py-2 bg-pink-600 text-white rounded-md hover:bg-pink-700 transition-colors duration-200 my-2"
            >
              View Contract Details
            </button>
          );
        }
      });
    };

    return (
        <div className="message-content">
            {processMessageContent(content)}
        </div>
    );
};

const Messages = () => {
    const { user } = useUser();
    const [profiles, setProfiles] = useState([]);
    const [messages, setMessages] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [selectedName, setSelectedName] = useState('');

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView();
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const fetchProfiles = useCallback(async () => {
        if (!user) {
            console.error("User is not defined");
            return;
        }

        try {
            const response = await axios.get(`${API_URL}/messages/latest`, { withCredentials: true });
            console.log("Response data: ", response.data);

            const uniqueProfiles = {};
            response.data.messages.forEach(message => {
                const otherUserId = message.sender_id === user.user_id ? message.receiver_id : message.sender_id;
                const uniqueKey = `${otherUserId}-${message.other_user_name}`;
                console.log("check"+message.toString());
                if (!uniqueProfiles[uniqueKey]) {
                    uniqueProfiles[uniqueKey] = {
                        senderId: message.sender_id,
                        receiverId: message.receiver_id,
                        otherUserId: otherUserId,
                        otherUserName: message.other_user_name,
                        content: message.content,
                        timestamp: message.timestamp,
                        status: message.status
                    };
                } else {
                    if (new Date(message.timestamp) > new Date(uniqueProfiles[uniqueKey].timestamp)) {
                        uniqueProfiles[uniqueKey] = {
                            senderId: message.sender_id,
                            receiverId: message.receiver_id,
                            otherUserId: otherUserId,
                            content: message.content,
                            timestamp: message.timestamp,
                            status: message.status
                        };
                    }
                }
            });

            const profilesArray = Object.values(uniqueProfiles);
            console.log("Unique profiles: ", profilesArray);

            setProfiles(profilesArray);
            console.log(profiles);
        } catch (error) {
            console.error('Failed to fetch profiles:', error);
        }
    }, [user]);

    useEffect(() => {
        fetchProfiles();
    }, [fetchProfiles]);

    const fetchMessages = async (senderId, receiverId, otherUserName) => {
        console.log("here"+user.user_id);
        if (user.user_id === receiverId) {
            receiverId = senderId
            senderId = user.user_id
        }
        console.log("here1"+receiverId);
        console.log("here2"+otherUserName);
        setSelectedProfile(receiverId)
        setSelectedName(otherUserName);
        try {
            const response = await axios.get(`${API_URL}/messages/conversation/${senderId}/${receiverId}`, { withCredentials: true });
            console.log('Here',response)
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const handleSendMessage = async () => {
        if (!selectedProfile) {
            console.error('No profile selected');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/contact`, {
                contactDetails: newMessage,
                senderID: user.user_id,
                recipientID: selectedProfile
            }, { withCredentials: true });
            if (response.status === 201) {
                const { sender_name, content, timestamp } = response.data;
                setMessages([...messages, { content, timestamp, sender_id: user.user_id, sender_name }]);
                setNewMessage('');
                fetchProfiles();
            }
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const confirmBooking = async (dateId, receiverId) => {
        try {
            const response = await fetch(`${API_URL}/confirm-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date_id: dateId, receiver_id: receiverId }),
                credentials: 'include'
            });
    

            const data = await response.json();
            alert(data.message);
            if (response.status === 200) {
                // Update the message status in the state instead of reloading
                setMessages(messages.map(msg => {
                    if (msg.date_id === dateId) {
                        return { ...msg, status: 'booked' };
                    }
                    fetchProfiles();
                    return msg;
                }));
            }
        } catch (error) {
            console.error('Error confirming booking:', error);
            alert('Error confirming booking');
        }
    };

    const denyBooking = async (dateId) => {
        try {
            const response = await fetch(`${API_URL}/deny-booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ date_id: dateId }),
                credentials: 'include'
            });

            const data = await response.json();
            alert(data.message);
            if (response.status === 200) {
                // Update the message status in the state instead of reloading
                setMessages(messages.map(msg => {
                    if (msg.date_id === dateId) {
                        return { ...msg, status: 'available' };
                    }
                    fetchProfiles();
                    return msg;
                }));
            }
        } catch (error) {
            console.error('Error denying booking:', error);
            alert('Error denying booking');
        }
    };

    const handleButtonClick = async (messageId, event) => {
        const dateId = event.target.getAttribute('data-date-id');
        const receiverId = event.target.getAttribute('data-receiver-id');
        try {
            fetch(`${API_URL}/update_booking_message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ messageId })
            });
        } catch (error) {
            console.error('Error processing message:', error);
        }
        if (!dateId || !receiverId) return; // Check both IDs are present
        if (event.target.classList.contains('confirm-button')) {
            confirmBooking(dateId, receiverId);
        } else if (event.target.classList.contains('deny-button')) {
            denyBooking(dateId, receiverId);
        }
    };
    if (!user) {
        return <div className="messages-container">Loading...</div>;
    }
    console.log(selectedProfile)
    return (
        <div className="messages-container"
             style={{overflowY: 'auto', maxHeight: '1150px', border: '1px solid #ccc'}}>
            <div className="profiles-list">
                {profiles.map((profile, index) => (
                    <div
                        key={index}
                        className={`profile-item ${
                            selectedProfile === profile.senderId || selectedProfile === profile.receiverId ? 'selected' : ''
                        }`}
                        onClick={() => fetchMessages(profile.senderId, profile.receiverId, profile.otherUserName)}
                    >
                        {profile.status === 'Delivered' && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px' // Adds spacing between the dot and the text
                                }}
                            >
                          <span
                              style={{
                                  display: 'inline-block',
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor: '#EF6191',
                                  borderRadius: '50%',
                              }}
                              title="Delivered"
                          ></span>
                                {user.user_id === profile.receiverId && (
                                    <h6 style={{margin: 0}}>Received - Unread</h6>
                                )}
                                {user.user_id === profile.senderId && (
                                    <h6 style={{margin: 0}}>Sent - Unread</h6>
                                )}
                            </div>
                        )}
                        {profile.status !== 'Delivered' && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px' // Adds spacing between the dot and the text
                                }}
                            >
                                {user.user_id === profile.receiverId && (
                                    <h6 style={{margin: 0}}>Received - Read</h6>
                                )}
                                {user.user_id === profile.senderId && (
                                    <h6 style={{margin: 0}}>Sent - Read</h6>
                                )}
                            </div>
                        )}
                        <p>
                            <strong>{profile.otherUserName}:</strong>{" "}
                            {profile.content.length > 50
                                ? `${profile.content.slice(0, 50)}...`
                                : profile.content}
                        </p>
                        <span>{new Date(profile.timestamp).toLocaleString()}</span>
                    </div>
                ))}
            </div>
            <div className='message-content'>
                <div className="messages-list">
                    {(e) => {
                        e.stopPropagation(); // Stop scroll from affecting the parent
                    }}
                    {selectedProfile && (
                        <div className="sticky-header">
                            <h2 style={{textAlign: 'center', padding: '10px'}}>
                                {selectedName}
                            </h2>
                        </div>
                    )}
                    {messages.map((msg, index) => (
                        <div 
                        key={index} 
                        className={`message-item ${msg.sender_id === user.user_id ? 'sent' : 'received'}`}
                      >
                        <div className={`message-item-content ${msg.sender_id === user.user_id ? 'sent' : 'received'}`}>
                          <MessageContent content={msg.content} />
                        </div>
                        <div className={`message-item-time ${msg.sender_id === user.user_id ? 'sent' : 'received'}`}>
                          {new Date(msg.timestamp).toLocaleString()} {msg.status}
                        </div>
                      
                        {/* Only put your onClick (handleButtonClick) on buttons that require it */}
                        {msg.needsApproval && (
                          <>
                            <button
                              className="confirm-button"
                              data-date-id={msg.date_id}
                              data-receiver-id={msg.sender_id}
                              onClick={(e) => handleButtonClick(msg.message_id, e)}
                            >
                              Confirm
                            </button>
                            <button
                              className="deny-button"
                              data-date-id={msg.date_id}
                              data-receiver-id={msg.sender_id}
                              onClick={(e) => handleButtonClick(msg.message_id, e)}
                            >
                              Deny
                            </button>
                          </>
                        )}
                      </div>
                    ))}
                    <div ref={messagesEndRef}/>
                </div>
                <div className="message-input">
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type a message..."
                    />
                    <button onClick={handleSendMessage}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default Messages;