import React, { useState, useEffect } from 'react';
import { 
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  Avatar,
  Fade,
  Chip,
  Tabs,
  Tab
} from '@mui/material';
import {
  Clock,
  MapPin,
  Music,
  Users,
  Building,
  AlertCircle,
  AlertTriangle,
  FileX
} from 'lucide-react';
import API_URL from '../config/apiUrl';

import axios from 'axios';


const ArtistCancellations = () => {
  const [bookings, setBookings] = useState({ cancelled_dates: [], cancelled_contracts: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const fetchCancellations = async () => {
      try {
        const response = await axios.get(`${API_URL}/ArtistCancellations`, {
          withCredentials: true
        });
        setBookings(response.data);
        console.log('THE BOOKING DATE: ', response.data)
        setLoading(false);
      } catch (err) {
        console.error('Error fetching cancellations:', err);
        setError('Failed to load cancellation data');
        setLoading(false);
      }
    };

    fetchCancellations();
  }, []);

  const handleDetailsClick = (booking) => {
    console.log('BUTOON CLICKED')
    console.log(booking)
    setSelectedBooking(booking);
    setShowDetailsPopup(true);
  };

  const CancellationCard = ({ booking }) => (
    <Fade in timeout={500}>
      <Paper
        elevation={0}
        sx={{
          mb: 3,
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          overflow: 'hidden',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)',
            borderColor: 'rgba(146, 74, 174, 0.3)',
          }
        }}
      >
        <Box sx={{ p: 3, position: 'relative' }}>
          <Box sx={{ 
            position: 'absolute', 
            top: 16, 
            right: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 1
          }}>
            {booking.is_contract_cancellation ? (
              <Chip
                icon={<FileX size={16} />}
                label="Contract Cancellation"
                sx={{
                  bgcolor: 'rgba(211, 47, 47, 0.1)',
                  color: 'rgb(211, 47, 47)',
                  '& .MuiChip-icon': {
                    color: 'rgb(211, 47, 47)'
                  }
                }}
              />
            ) : (
              <Chip
                icon={<AlertTriangle size={16} />}
                label="Date Cancellation"
                sx={{
                  bgcolor: 'rgba(255, 152, 0, 0.1)',
                  color: '#EF6191',
                  '& .MuiChip-icon': {
                    color: '#EF6191'
                  }
                }}
              />
            )}
            {booking.refund_status && (
              <Chip
                label={`Refund: ${booking.refund_status}`}
                size="small"
                sx={{
                  bgcolor: 'rgba(0, 0, 0, 0.05)',
                  color: 'text.secondary'
                }}
              />
            )}
          </Box>
          
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Avatar
                src={booking.artist_url}
                sx={{ 
                  width: 80, 
                  height: 80,
                  border: '3px solid rgba(146, 74, 174, 0.1)'
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  color: '#EF6191',
                  mb: 1
                }}
              >
                {booking.venue_name}
              </Typography>
              
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Clock size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.start_time} - {booking.end_time}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MapPin size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.date}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Music size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      {booking.genre}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Users size={20} className="text-purple-500 mr-2" />
                    <Typography variant="body2" color="text.secondary">
                      Expected: {booking.performance_type}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {booking.cancellation_reason && (
                <Box 
                  sx={{ 
                    mt: 2,
                    p: 2,
                    bgcolor: 'rgba(211, 47, 47, 0.05)',
                    borderRadius: 1,
                    display: 'flex',
                    gap: 1
                  }}
                >
                  <AlertCircle size={20} color="rgb(211, 47, 47)" />
                  <Typography variant="body2" color="text.secondary">
                    {booking.cancellation_reason}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3,
            pt: 3,
            borderTop: '1px solid rgba(0, 0, 0, 0.06)'
          }}>
            <Typography variant="h6" sx={{ color: '#EF6191', fontWeight: 500 }}>
              Original Fee: ${booking.min_cost}
            </Typography>
            {/* <Button
              variant="contained"
              onClick={() => handleDetailsClick(booking)}
              sx={{
                bgcolor: '#EF6191',
                '&:hover': {
                  bgcolor: '#7a3b91'
                }
              }}
            >
              View Details
            </Button> */}
          </Box>
        </Box>
      </Paper>
    </Fade>
  );

  return (
    <Box sx={{ 
      maxWidth: 1200, 
      mx: 'auto', 
      p: { xs: 2, sm: 3, md: 4 },
      bgcolor: '#ffffff'
    }}>
      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 2, 
            fontWeight: 600,
            color: 'text.primary',
            letterSpacing: '-0.02em'
          }}
        >
          Cancellation Management
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'text.secondary',
            lineHeight: 1.6
          }}
        >
            Review your cancelled bookings and terminated contracts with venues.
        </Typography>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ mb: 4 }}
      >
        <Tab 
        label={`Cancelled Venue Dates (${bookings.cancelled_dates.length})`}
        sx={{ color: '#EF6191' }}
        />
        <Tab 
        label={`Your Cancelled Contracts (${bookings.cancelled_contracts.length})`}
        sx={{ color: '#EF6191' }}
        />
      </Tabs>

      {loading ? (
        <Typography textAlign="center" color="text.secondary">
          Loading cancellations...
        </Typography>
      ) : error ? (
        <Typography textAlign="center" color="error">
          {error}
        </Typography>
      ) : (
        <Box>
          {activeTab === 0 ? (
            bookings.cancelled_dates.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No cancelled dates at the moment.
              </Typography>
            ) : (
              bookings.cancelled_dates.map((booking) => (
                <CancellationCard key={booking.booking_id} booking={booking} />
              ))
            )
          ) : (
            bookings.cancelled_contracts.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No cancelled contracts at the moment.
              </Typography>
            ) : (
              bookings.cancelled_contracts.map((booking) => (
                <CancellationCard key={booking.booking_id} booking={booking} />
              ))
            )
          )}
        </Box>
      )}

    </Box>
  );
};

export default ArtistCancellations;