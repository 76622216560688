import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Container,
  IconButton,
  styled,
  createTheme,
  ThemeProvider,
  CircularProgress
} from '@mui/material';
import { 
  AddCircle, 
  ViewList, 
  Info, 
  CalendarToday, 
  NotificationsNone, 
  EventAvailable,
  AccessTime
} from '@mui/icons-material';
import API_URL from '../config/apiUrl';
import DatePicker from 'react-datepicker';
import axios from 'axios';

// Create custom theme matching the venue landing page
const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6191',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});

const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'available':
      return '#4CAF50'; 
    case 'interest_shown':
      return '#EF6191'; 
    case 'booked':
      return '#924AAE'; 
    default:
      return '#757575'; 
  }
};


// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
}));

const QuickActionCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: '#f5f5f7',
  height: '100%',
  '&:hover': {
    backgroundColor: '#e8e8e8',
    cursor: 'pointer',
  },
  '& .MuiIconButton-root': {
    color: theme.palette.primary.main,
  },
}));

const BrandedTypography = styled(Typography)(({ theme }) => ({
  '&.brand-heading': {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const LatestBookingCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: theme.shadows[3],
  backgroundColor: '#ffffff',
  position: 'relative',
}));

const VenueBookingLandingPage = ({ onCreateBooking, onViewBookings, onViewCalender, onViewBids, venue_user_id, venue_profile_id }) => {
  const [latestBooking, setLatestBooking] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookedBookings, setBookedBookings] = useState([]);
  const [pendingBids, setPendingBids] = useState([]);
  const [allDates, setAllDates] = useState([]);

  useEffect(() => {
    const fetchLatestBooking = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/api/latest-booking/${venue_profile_id}`);
        setLatestBooking(response.data);
        console.log('',response.data);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch the latest booking; Maybe Post a Date and get Booked!');
        setIsLoading(false);
      }
    };

    fetchLatestBooking();
  }, [venue_profile_id]);

  useEffect(() => {
    const fetchDates = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/api/venue-bookings/${venue_profile_id}`);
        const fetchedBookings = response.data;
        console.log(fetchedBookings)
        const convertedAllDates = fetchedBookings
          .filter(booking => booking.status !== 'booked')
          .map(booking => {
            const date = new Date(booking.date);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            return date;
          });
        setAllDates(convertedAllDates);

        setBookedBookings(fetchedBookings.filter(booking => booking.status === 'booked'));
        const currentDate = new Date();
        // Set current date to start of day for accurate comparison
        currentDate.setHours(0, 0, 0, 0);
        const convertedPendingBids = fetchedBookings
        .filter(booking => {
          const bookingDate = new Date(booking.date);
          bookingDate.setHours(0, 0, 0, 0);
          return (
            booking.status === 'interest_shown' && 
            bookingDate > currentDate
          );
        })
        .map(booking => {
          const date = new Date(booking.date);
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          return date;
        });
      setPendingBids(convertedPendingBids);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        setIsLoading(false);
      }
    };

    fetchDates();
  }, [venue_profile_id]);

  console.log('ALL DATES',allDates);
  const quickActions = [
    {
      icon: <AddCircle />,
      title: 'Create Booking',
      description: 'Post new dates for performers',
      action: onCreateBooking
    },
    {
      icon: <ViewList />,
      title: 'View Bookings',
      description: 'Manage existing bookings',
      action: onViewBookings
    },
    {
      icon: <NotificationsNone />,
      title: 'Pending Bids',
      description: `${pendingBids.length} bids awaiting review`,
      action: onViewBids
    },
    {
      icon: <EventAvailable />,
      title: 'Calendar',
      description: 'View your booking schedule',
      action: onViewCalender
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Header Section */}
        <Box sx={{ mb: 8, textAlign: 'center' }}>
          <BrandedTypography variant="h3" className="brand-heading" gutterBottom>
            Venue Booking Dashboard
          </BrandedTypography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 6 }}>
            Manage your venue bookings with ease and connect with talented performers.
          </Typography>
        </Box>

        {/* Quick Actions Grid */}
        <Grid container spacing={4} sx={{ mb: 8 }}>
          {quickActions.map((action, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <QuickActionCard onClick={action.action}>
                <CardContent sx={{ textAlign: 'center', py: 4 }}>
                  <IconButton sx={{ mb: 2, p: 2, backgroundColor: 'rgba(239, 97, 145, 0.1)' }}>
                    {action.icon}
                  </IconButton>
                  <Typography variant="h6" gutterBottom>
                    {action.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {action.description}
                  </Typography>
                </CardContent>
              </QuickActionCard>
            </Grid>
          ))}
        </Grid>

        {/* Calendar and Latest Booking Section */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <StyledCard sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CalendarToday color="primary" />
                    Available & Interest Shown Dates
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'center',
                  width: '100%'
                }}>
                  <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    inline
                    highlightDates={allDates}
                    className="date-picker"
                  />
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <LatestBookingCard>
              {isLoading ? (
                <Box sx={{ p: 4, textAlign: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : error ? (
                <Box sx={{ p: 4 }}>
                  <Typography color="error">{error}</Typography>
                </Box>
              ) : latestBooking ? (
                <>
                  <CardMedia
                    component="img"
                    height="200"
                    image={latestBooking.image || "/api/placeholder/800/200"}
                    alt="Latest Booking"
                    sx={{ objectFit: 'cover' }}
                  />
                  <CardContent>
                  <Grid container alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Info color="primary" />
                      <Typography variant="h6">
                        Latest Booking Details
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ color: getStatusColor(latestBooking.status) }}>
                        Status: {latestBooking.status}
                      </Typography>
                    </Grid>
                  </Grid>
                    
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary">Date</Typography>
                        <Typography variant="body1">
                          {new Date(latestBooking.date).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary">Time</Typography>
                        <Typography variant="body1">
                          {latestBooking.startTime} - {latestBooking.endTime}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary">Performance Type</Typography>
                        <Typography variant="body1">{latestBooking.details.performanceType}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2" color="text.secondary">Genres</Typography>
                        <Typography variant="body1">{latestBooking.details.genres.join(', ')}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="text.secondary">Price Range</Typography>
                        <Typography variant="body1">
                          ${latestBooking.details.priceRange.min} - ${latestBooking.details.priceRange.max}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              ) : (
                <Box sx={{ p: 4 }}>
                  <Typography variant="body1" color="text.secondary">
                    No bookings found for this venue.
                  </Typography>
                </Box>
              )}
            </LatestBookingCard>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default VenueBookingLandingPage;