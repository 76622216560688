import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Grid, Chip, Paper, Button,
  Pagination, Tabs, Tab, Dialog, DialogTitle, 
  DialogContent, DialogContentText, DialogActions, 
  TextField, Rating, CircularProgress, Alert, IconButton
} from '@mui/material';
import axios from 'axios';
import API_URL from '../config/apiUrl';
import { Event, AccessTime, Place, Stars,  Close as CloseIcon  } from '@mui/icons-material';

const ArtistReviews = () => {
  const [reviewsData, setReviewsData] = useState({
    toWrite: [],
    yourReviews: [],
    averageRatingGiven: 0
  });

  // We can track overall stats if desired (e.g., how many gigs need reviews, etc.)
  const [stats, setStats] = useState({
    totalToWrite: 0,
    totalWritten: 0
  });

  // Tabs: 0 => "Write Reviews", 1 => "Your Reviews"
  const [activeTab, setActiveTab] = useState(0);

  // Pagination
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;

  // Review Modal State
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(0);
  const [selectedReviewItem, setSelectedReviewItem] = useState(null);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Decide which list (toWrite or yourReviews) based on the active tab
  const currentReviewsList = activeTab === 0 
    ? reviewsData.toWrite 
    : reviewsData.yourReviews;

  // Paginated items
  const indexOfLast = page * reviewsPerPage;
  const indexOfFirst = indexOfLast - reviewsPerPage;
  const currentPageReviews = currentReviewsList.slice(indexOfFirst, indexOfLast);
  const totalPages = Math.ceil(currentReviewsList.length / reviewsPerPage);

  // When user clicks "Write Review" in the first tab
  const handleWriteReview = (item) => {
    setSelectedReviewItem(item);
    setReviewText('');
    setReviewRating(0);
    setIsReviewModalOpen(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Add loading state for submit action
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch data on component mount
  useEffect(() => {
    fetchReviewData();
  }, []);

  const fetchReviewData = async () => {
    setIsLoading(true);
    try {
      const [toWriteRes, yourReviewsRes, avgRatingRes] = await Promise.all([
        fetch(`${API_URL}/api/reviews/to-write`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        }),
        fetch(`${API_URL}/api/reviews/your-reviews`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        }),
        fetch(`${API_URL}/api/reviews/avg-rating-given`, {
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
      ]);

      if (!toWriteRes.ok || !yourReviewsRes.ok) {
        throw new Error('Failed to fetch reviews');
      }

      const toWriteData = await toWriteRes.json();
      const yourReviewsData = await yourReviewsRes.json();
      const avgRatingData = await avgRatingRes.json();

      setReviewsData({
        toWrite: toWriteData.data,
        yourReviews: yourReviewsData.data,
        averageRatingGiven: avgRatingData.data.averageRating
      });

      // Update stats
      setStats({
        totalToWrite: toWriteData.data.length,
        totalWritten: yourReviewsData.data.length
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitReview = async () => {
    if (!reviewRating || !reviewText.trim()) {
      alert('Please provide both a rating and review text');
      return;
    }
  
    setIsSubmitting(true);
    try {
      await axios.post(`${API_URL}/api/reviews`, {
        contractId: selectedReviewItem.id,
        rating: reviewRating,
        comment: reviewText.trim()
      }, { withCredentials: true });
  
      await fetchReviewData();
      setIsReviewModalOpen(false);
      setReviewText('');
      setReviewRating(0);
      alert('Review submitted successfully!');
    } catch (err) {
      alert('Failed to submit review: ' + (err.response?.data?.message || err.message));
    } finally {
      setIsSubmitting(false);
    }
  };

  // "Card" for each item in "Write Reviews"
  // or displaying an existing review in "Your Reviews"
  const ReviewCard = ({ item, isToWrite = false }) => {
    const dateObject = new Date(item.date);

    // If this is a previously written review, we might show existing rating/comment
    const existingRating = item.existingRating || null;
    const existingComment = item.existingComment || null;
    console.log(item)
    return (
      <Paper 
        elevation={0}
        sx={{
          mb: 2,
          borderRadius: 2,
          border: '1px solid rgba(0, 0, 0, 0.08)',
          position: 'relative',
          p: 3,
          '&:hover': {
            boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.2)'
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {item.venueName || 'Venue Name'}
          </Typography>
          {isToWrite ? (
            <Chip
              label="Needs Review"
              sx={{
                bgcolor: 'rgba(255, 193, 7, 0.1)',
                color: '#FFC107',
                fontWeight: 500
              }}
            />
          ) : (
            <Chip
              label="Your Review"
              sx={{
                bgcolor: 'rgba(146, 74, 174, 0.08)',
                color: '#924AAE',
                fontWeight: 500
              }}
            />
          )}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Event sx={{ color: '#924AAE', mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {dateObject.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTime sx={{ color: '#924AAE', mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {item.startTime} - {item.endTime}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Place sx={{ color: '#924AAE', mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {item.location}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Stars sx={{ color: '#924AAE', mr: 1 }} />
              <Typography variant="body2" color="text.secondary">
                {item.experienceLevel || item.existingRating}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {!isToWrite && existingRating && (
          <Box sx={{ mt: 3, p: 2, borderRadius: 1, bgcolor: 'rgba(0,0,0,0.04)' }}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              <strong>Your Rating:</strong> {existingRating} star{existingRating !== 1 && 's'}
            </Typography>
            <Typography variant="body2">
              <strong>Comments:</strong> {existingComment}
            </Typography>
          </Box>
        )}

        {isToWrite && (
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button
              variant="contained"
              sx={{
                bgcolor: '#924AAE',
                '&:hover': { bgcolor: '#7a3b91' }
              }}
              onClick={() => handleWriteReview(item)}
            >
              Write Review
            </Button>
          </Box>
        )}
      </Paper>
    );
  };
  console.log(reviewsData);
  const StatCard = ({ title, value }) => (
    <Paper 
      elevation={0}
      sx={{
        p: 3,
        height: '100%',
        bgcolor: 'rgba(146, 74, 174, 0.04)',
        borderRadius: 2,
        border: '1px solid rgba(146, 74, 174, 0.1)',
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 24px -10px rgba(146, 74, 174, 0.15)',
          bgcolor: 'rgba(146, 74, 174, 0.08)',
        }
      }}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ color: '#924AAE', fontWeight: 500 }}>
        {value}
      </Typography>
    </Paper>
  );

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: { xs: 2, sm: 3, md: 4 }, bgcolor: '#fff' }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 6, maxWidth: 720 }}>
        <Typography 
          variant="h3" 
          sx={{ mb: 2, fontWeight: 600, color: 'text.primary' }}
        >
          Artist Reviews
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', lineHeight: 1.6 }}>
          Here you can review past gigs and view your existing reviews.
        </Typography>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={handleTabChange}
        sx={{
          mb: 4,
          '& .MuiTab-root': {
            color: '#924AAE',
            textTransform: 'none',
            '&.Mui-selected': {
              color: '#924AAE',
              fontWeight: 600
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#924AAE'
          }
        }}
      >
        <Tab label={`Write Reviews (${reviewsData.toWrite.length})`} />
        <Tab label={`Your Reviews (${reviewsData.yourReviews.length})`} />
      </Tabs>

      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={6}>
          <StatCard 
            title={activeTab === 0 ? "Need to Review" : "Your Reviews"}
            value={activeTab === 0 ? stats.totalToWrite : stats.totalWritten}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StatCard 
            title={activeTab === 0 ? "Average Rating Given" : "Average Rating Received"}
            value={activeTab === 0 ? reviewsData.averageRatingGiven : reviewsData.yourReviews.length > 0 
              ? (reviewsData.yourReviews.reduce((acc, rev) => acc + rev.existingRating, 0) / reviewsData.yourReviews.length).toFixed(1)
              : "N/A"
           
            }
          />
        </Grid>
      </Grid>

      <Box>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
            <CircularProgress sx={{ color: '#924AAE' }} />
          </Box>
        ) : currentPageReviews.length === 0 ? (
          <Typography 
            textAlign="center" 
            color="text.secondary" 
            sx={{ py: 8 }}
          >
            {activeTab === 0 
              ? "No gigs are waiting for your review."
              : "You have no previous reviews."}
          </Typography>
        ) : (
          currentPageReviews.map((item) => (
            <ReviewCard 
              key={item.id} 
              item={item}
              isToWrite={activeTab === 0} 
            />
          ))
        )}
      </Box>

      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            size="large"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#924AAE',
                '&:hover': {
                  bgcolor: 'rgba(146, 74, 174, 0.04)',
                },
              },
              '& .MuiPaginationItem-page.Mui-selected': {
                bgcolor: '#924AAE',
                color: 'white',
                '&:hover': {
                  bgcolor: '#7a3b91',
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog 
        open={isReviewModalOpen} 
        onClose={() => !isSubmitting && setIsReviewModalOpen(false)} 
        fullWidth 
        maxWidth="sm"
        TransitionProps={{
          timeout: 300
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '24px',
            background: 'linear-gradient(145deg, #fff 0%, #fcfaff 100%)',
            backdropFilter: 'blur(20px)',
            border: '1px solid rgba(239, 97, 145, 0.1)', // Changed from rgba(146, 74, 174, 0.1)
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '180px',
              background: 'linear-gradient(145deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
              zIndex: 0
            }
          }
        }}
      >
        <Box sx={{ 
          position: 'absolute', 
          top: 0, 
          right: 0,
          m: 2
        }}>
          <IconButton
            onClick={() => !isSubmitting && setIsReviewModalOpen(false)}
            sx={{
              color: '#EF6191',
              backgroundColor: 'rgba(239, 97, 145, 0.08)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
              backdropFilter: 'blur(8px)',
              '&:hover': {
                backgroundColor: 'rgba(239, 97, 145, 0.12)', // Changed rgba(146, 74, 174, 0.12) to rgba(239, 97, 145, 0.12)
                transform: 'scale(1.05)',
              },
              transition: 'all 0.2s ease-in-out'
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ px: 4, pt: 4, pb: 2, position: 'relative' }}>
            <Typography variant="h4" sx={{ 
              fontWeight: 700,
              background: 'linear-gradient(135deg, #EF6191 0%, #B366CC 100%)', // Consider updating #B366CC if desired
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 1
            }}>
              Write a Review
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
              Share your experience and help others make informed decisions
            </Typography>
          </Box>

          <Box sx={{ 
            mx: 4,
            p: 3, 
            bgcolor: 'rgba(239, 97, 145, 0.03)', // Changed rgba(146, 74, 174, 0.03) to rgba(239, 97, 145, 0.03)
            borderRadius: '20px',
            border: '1px solid rgba(239, 97, 145, 0.08)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
            mb: 4,
            boxShadow: 'inset 0 2px 4px rgba(239, 97, 145, 0.02)' // Changed rgba(146, 74, 174, 0.02) to rgba(239, 97, 145, 0.02)
          }}>
            <Typography variant="h5" sx={{ 
              color: '#EF6191', 
              fontWeight: 700, 
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              {selectedReviewItem?.venueName || 'Venue'}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5,
                  p: 1.5,
                  borderRadius: '12px',
                  bgcolor: 'rgba(239, 97, 145, 0.04)', // Changed rgba(146, 74, 174, 0.04) to rgba(239, 97, 145, 0.04)
                  border: '1px solid rgba(239, 97, 145, 0.06)' // Changed rgba(146, 74, 174, 0.06) to rgba(239, 97, 145, 0.06)
                }}>
                  <Event sx={{ color: '#EF6191' }} />
                  <Typography variant="body2" sx={{ 
                    color: 'text.primary',
                    fontWeight: 500
                  }}>
                    {new Date(selectedReviewItem?.date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5,
                  p: 1.5,
                  borderRadius: '12px',
                  bgcolor: 'rgba(239, 97, 145, 0.04)', // Changed rgba(146, 74, 174, 0.04) to rgba(239, 97, 145, 0.04)
                  border: '1px solid rgba(239, 97, 145, 0.06)' // Changed rgba(146, 74, 174, 0.06) to rgba(239, 97, 145, 0.06)
                }}>
                  <Place sx={{ color: '#EF6191' }} />
                  <Typography variant="body2" sx={{ 
                    color: 'text.primary',
                    fontWeight: 500
                  }}>
                    {selectedReviewItem?.location}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ px: 4, mb: 4 }}>
            <Typography variant="h6" sx={{ 
              mb: 2,
              color: '#EF6191',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              Rate Your Experience
            </Typography>
            <Box sx={{ 
              p: 3, 
              borderRadius: '16px',
              bgcolor: 'rgba(239, 97, 145, 0.02)', // Changed rgba(146, 74, 174, 0.02) to rgba(239, 97, 145, 0.02)
              border: '1px solid rgba(239, 97, 145, 0.08)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
              display: 'flex',
              justifyContent: 'center'
            }}>
              <Rating 
                name="reviewRating" 
                value={reviewRating} 
                onChange={(event, newValue) => {
                  setReviewRating(newValue);
                }}
                disabled={isSubmitting}
                size="large"
                sx={{
                  '& .MuiRating-icon': {
                    color: 'rgba(239, 97, 145, 0.2)', // Changed rgba(146, 74, 174, 0.2) to rgba(239, 97, 145, 0.2)
                    fontSize: '2.5rem',
                    transition: 'all 0.2s ease-in-out'
                  },
                  '& .MuiRating-iconFilled': {
                    color: '#EF6191'
                  },
                  '& .MuiRating-iconHover': {
                    color: '#B366CC', // You might want to update this to a complementary color if you prefer
                    transform: 'scale(1.1)'
                  }
                }}
              />
            </Box>
          </Box>

          <Box sx={{ px: 4, mb: 4 }}>
            <Typography variant="h6" sx={{ 
              mb: 2,
              color: '#EF6191',
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              Share Your Thoughts
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Tell others about your experience with the venue..."
              variant="outlined"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              disabled={isSubmitting}
              error={reviewText.trim().length === 0}
              helperText={reviewText.trim().length === 0 ? "Please share your experience" : ""}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '16px',
                  backgroundColor: 'rgba(239, 97, 145, 0.02)', // Changed rgba(146, 74, 174, 0.02) to rgba(239, 97, 145, 0.02)
                  transition: 'all 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: 'rgba(239, 97, 145, 0.04)', // Changed rgba(146, 74, 174, 0.04) to rgba(239, 97, 145, 0.04)
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#fff',
                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.08)' // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
                  },
                  '& fieldset': {
                    borderColor: 'rgba(239, 97, 145, 0.1)' // Changed rgba(146, 74, 174, 0.1) to rgba(239, 97, 145, 0.1)
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(239, 97, 145, 0.2)' // Changed rgba(146, 74, 174, 0.2) to rgba(239, 97, 145, 0.2)
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#EF6191'
                  }
                },
                '& .MuiFormLabel-root': {
                  color: 'text.secondary'
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: '#EF6191'
                }
              }}
            />
          </Box>

          <Box sx={{ 
            px: 4, 
            py: 3, 
            borderTop: '1px solid rgba(239, 97, 145, 0.08)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
          }}>
            <Button 
              onClick={() => setIsReviewModalOpen(false)} 
              disabled={isSubmitting}
              sx={{
                borderRadius: '12px',
                padding: '12px 24px',
                color: '#EF6191', // Changed from '#924AAE' to '#EF6191' for consistency
                backgroundColor: 'rgba(239, 97, 145, 0.04)', // Changed rgba(146, 74, 174, 0.04) to rgba(239, 97, 145, 0.04)
                '&:hover': {
                  backgroundColor: 'rgba(239, 97, 145, 0.08)', // Changed rgba(146, 74, 174, 0.08) to rgba(239, 97, 145, 0.08)
                },
                transition: 'all 0.2s ease-in-out'
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleSubmitReview} 
              variant="contained" 
              disabled={isSubmitting || !reviewRating || !reviewText.trim()}
              sx={{ 
                background: 'linear-gradient(135deg, #EF6191 0%, #B366CC 100%)', // Consider updating #B366CC if desired
                color: '#fff',
                borderRadius: '12px',
                padding: '12px 32px',
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'none',
                boxShadow: '0 4px 12px rgba(239, 97, 145, 0.2)', // Changed rgba(146, 74, 174, 0.2) to rgba(239, 97, 145, 0.2)
                '&:hover': {
                  boxShadow: '0 6px 16px rgba(239, 97, 145, 0.3)', // Changed rgba(146, 74, 174, 0.3) to rgba(239, 97, 145, 0.3)
                  transform: 'translateY(-1px)'
                },
                '&.Mui-disabled': {
                  background: 'rgba(239, 97, 145, 0.12)', // Changed rgba(146, 74, 174, 0.12) to rgba(239, 97, 145, 0.12)
                  color: 'rgba(255, 255, 255, 0.8)'
                },
                transition: 'all 0.2s ease-in-out'
              }}
            >
              {isSubmitting ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={16} sx={{ color: '#fff' }} />
                  Submitting...
                </Box>
              ) : (
                'Submit Review'
              )}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ArtistReviews;