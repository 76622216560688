import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  Collapse
} from '@mui/material';
import { styled } from '@mui/system';
import emailjs from 'emailjs-com';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  marginTop: theme.spacing(1),
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& .MuiAlert-icon': {
    fontSize: '1.5rem'
  },
  '& .MuiAlert-message': {
    fontSize: '0.95rem',
    fontFamily: 'Poppins, sans-serif',
  }
}));

function ContactDialog({ open, onClose }) {
  useEffect(() => {
    emailjs.init('gHDv2j1boFBdQTfm3');
  }, []);

  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: ''
  });
  
  // Changed to store severity specifically as 'success' or 'error'
  const [status, setStatus] = useState({ severity: '', message: '' });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    emailjs.send('get_email', 'tem', formData)
      .then(
        (result) => {
          setStatus({
            severity: 'success',
            message: 'Your message has been sent successfully! We\'ll get back to you soon.'
          });
          setFormData({ email: '', subject: '', message: '' });
          setLoading(false);
        },
        (error) => {
          setStatus({
            severity: 'error',
            message: 'Unable to send message. Please try again or contact us directly.'
          });
          setLoading(false);
        }
      );
  };

  const handleClose = () => {
    setFormData({ email: '', subject: '', message: '' });
    setStatus({ severity: '', message: '' });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ 
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
        background: 'linear-gradient(135deg, #EF6191, #2962ff)',
        WebkitBackgroundClip: 'text',
        color: 'transparent'
      }}>
        Contact Us
      </DialogTitle>
      
      <DialogContent dividers>
        <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
          Fill out the form below and we'll get back to you as soon as possible.
        </Typography>

        <Collapse in={Boolean(status.message)}>
          {status.severity && (
            <StyledAlert 
              severity={status.severity}
              icon={status.severity === 'success' ? <CheckCircleOutlineIcon /> : <ErrorOutlineIcon />}
            >
              {status.message}
            </StyledAlert>
          )}
        </Collapse>

        <FormContainer onSubmit={handleSubmit}>
          <TextField
            required
            type="email"
            name="email"
            label="Your Email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            type="text"
            name="subject"
            label="Subject"
            value={formData.subject}
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            name="message"
            label="Message"
            value={formData.message}
            onChange={handleChange}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
          <Box textAlign="right">
            <Button 
              type="submit" 
              variant="contained"
              sx={{
                background: 'linear-gradient(90deg, #EF6191, #2962ff)',
                color: '#fff',
                fontWeight: 600,
                fontFamily: 'Poppins, sans-serif',
                textTransform: 'none',
              }}
              disabled={loading}
            >
              {loading ? 'Sending...' : 'Send'}
            </Button>
          </Box>
        </FormContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}
          sx={{
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'none'
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ContactDialog;