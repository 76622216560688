import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton, Chip , CardContent } from '@mui/material';
import { Search, FilterList, MoreVert, Event, AccessTime, Place } from '@mui/icons-material';
import './BookingStylesImp.css';
import ArtistBookingLandingPage from './ArtistBookingLandingPage';
import ArtistDiscover from './ArtistBookingDiscover';
import BookingsList from './ArtistBookingList';
import VendorMap from './Map';
import LocalVenues from './ArtistBookingLocalVenues';
import ActiveBids from './ArtistActiveBids';
import ArtistCancellations from './ArtistCancellations';
import ArtistReviews from './AristBookingReview';
import ConfirmedGigs from './ArtistConfirmedGigs';
import ArtistBookingLastMinute from './ArtistBookingLastMinute';
const ArtistBookingHomePage = ({ venueProfile }) => {
    const [currentPage, setCurrentPage] = useState('landing');
    const [selectedBooking, setSelectedBooking] = useState(null)
    const handleViewBookings = () => setCurrentPage('bookingList');
    const handleDiscover = () => setCurrentPage('discover');
    const handleBackToLanding = () => setCurrentPage('landing');
    const handleViewVenues = () => setCurrentPage('localVenues');
    const handleViewCancellations = () => setCurrentPage('Cancellations');
    return (
        <Box className="venue-booking-container" sx={{ display: 'flex' }}>
            {/* Sidebar */}
            <Box 
                className="venue-sidebar" 
                sx={{ 
                width: '240px', 
                bgcolor: '#e0e0e0', 
                color: '#fff', 
                minHeight: '100vh', 
                padding: 2 
                }}
            >
                <Typography 
                variant="h6" 
                sx={{ 
                    mb: 2, 
                    cursor: 'pointer', 
                    fontWeight: 'bold',
                    '&:hover': {
                    textDecoration: 'underline',
                    }
                }}
                onClick={handleBackToLanding}
                >
                Booking Dashboard
                </Typography>
                <List>
                    {['View Bookings', 'Confirmed Gigs', 'Active Bids','Cancellations', "See Who's Local", 'Discover Events', 'Priority Events', 'Reviews'].map((text) => (
                        <ListItem
                            button
                            key={text}
                            sx={{ 
                                fontFamily: "'Times New Roman', Times, serif", 
                                color: '#000000' 
                              }}
                            onClick={() => {
                                switch(text) {
                                    case 'View Bookings':
                                        handleViewBookings();
                                        break;
                                    case 'Discover Events':
                                        handleDiscover();
                                        break;
                                    case "See Who's Local":
                                        handleViewVenues();
                                        break;
                                    case 'Confirmed Gigs':
                                        setCurrentPage('confirmedGigs');
                                        break;
                                    case 'Active Bids':
                                        setCurrentPage('activebids');
                                        break;
                                    case 'Priority Events':
                                        setCurrentPage('lastMinuteEvents');
                                        break;
                                    case 'Cancellations':
                                            setCurrentPage('Cancellations');
                                            break;
                                    case 'Reviews':
                                        setCurrentPage('Reviews');
                                        break;
                                    default:
                                        handleBackToLanding();
                                }
                            }}
                        >
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Box>
            {/* Main content */}
            <Box className="venue-main-content" sx={{ flex: 1, padding: 4 }}>
                {currentPage === 'landing' && (
                    <ArtistBookingLandingPage
                        onViewBookings={handleViewBookings}
                        onFindEvent={handleDiscover}
                        setCurrentPage={setCurrentPage} 
                    />
                )}
                {currentPage === 'bookingList' && (
                    <BookingsList
                        setSelectedBooking={setSelectedBooking}
                        setCurrentPage={setCurrentPage}
                    />
                )}
                {currentPage === 'discover' && (
                    <ArtistDiscover />
                )}
                {currentPage === "localVenues" && (
                    <LocalVenues />
                )}
                {currentPage === 'confirmedGigs' && (
                    <ConfirmedGigs />
                )}
                {currentPage === 'activebids' && (
                    <ActiveBids />
                )}
                {currentPage === 'lastMinuteEvents' && (
                    <ArtistBookingLastMinute />
                )}
                {currentPage === 'Cancellations' && (
                    <ArtistCancellations />
                )}
                {currentPage === 'Reviews' && (
                    <ArtistReviews />
                )}
            </Box>
        </Box>
    );
};
export default ArtistBookingHomePage;