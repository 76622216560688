import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Snackbar,
  Alert,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField
} from '@mui/material';
import API_URL from '../config/apiUrl';
import { 
  Event, 
  Person, 
  AttachMoney, 
  Description, 
  Schedule,
  Place,
  CheckCircle,
  MusicNote,
  Close as CloseIcon
} from '@mui/icons-material';
import axios from 'axios';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const ContractDashboard = ({ contract, onBack, onContractSigned, page  }) => {
  console.log('CONTRACTS - ',contract);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSignedContract, setOpenSignedContract] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [openSoundCheckDialog, setOpenSoundCheckDialog] = useState(false);
  const [soundCheckOption, setSoundCheckOption] = useState('');
  const [customSoundCheckTime, setCustomSoundCheckTime] = useState(null);

  const isContractBooked = contract.status.toLowerCase() === 'signed' || contract.status.toLowerCase() === 'booked';
  const calculateDuration = (startTime, endTime) => {
    console.log('START TIME');
    console.log('START TIME', startTime);
    console.log('START TIME',endTime );
    // Parse the times and create Date objects
    const start = new Date(`2000-01-01T${startTime}:00`);
    let end = new Date(`2000-01-01T${endTime}:00`);
    
    // If end time is before start time, assume it's the next day
    if (end < start) {
      end = new Date(end.getTime() + 24 * 60 * 60 * 1000);
    }
    
    // Calculate the difference in milliseconds
    const diff = end - start;
    
    // Convert to hours and minutes
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    
    // Format the duration string
    return `${hours}h ${minutes}m`;
  };


  const handleSignContract = () => {
    if (!isContractBooked) {
      setOpenConfirmation(true);
    }
  };

  const handleViewSignedCopy = () => {
    setOpenSignedContract(true);
  };

  const handleCloseSignedCopy = () => {
    setOpenSignedContract(false);
  };

  
  const duration = calculateDuration(contract.startTime, contract.endTime);
  const eventStart = new Date(`2000-01-01T${contract.startTime}:00`);
  const intervals = [30, 60, 120, 180]; 
  
  function subtractMinutesAsString(date, mins) {
    const copy = new Date(date.getTime());
    copy.setMinutes(copy.getMinutes() - mins);
    return copy.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }
  
  const dynamicSoundCheckOptions = intervals.map((m) => ({
    label: subtractMinutesAsString(eventStart, m),
    value: String(m),    
    minutesBefore: m
  }));
  
  dynamicSoundCheckOptions.push({
    label: 'Custom Time',
    value: 'custom',
    minutesBefore: null
  });

  console.log(contract)
  console.log(`Duration: ${duration}`);
  const contractDetails = {
    id: contract?.id,  
    artist: contract?.other_party?.name,  
    event: contract?.booking_details?.genres, 
    date: contract?.date,  // Directly on contract object
    status: contract?.status,  // Directly on contract object
    venue: contract?.booking_details?.venueName,  // In booking_details
    fee: contract?.bid_amount,  // Directly on contract object
    duration: duration,  // Calculated value
    equipment: contract?.artist_notes,  // Directly on contract object
    specialRequirements: contract?.technical_requirements,
    contractTerms: [
      `Artist will start performance at ${contract.startTime}, and perform for approximately ${duration}.`,
      "Sound check at 6 PM",
      "Payment to be made immediately after the performance",
    ],
  };

  console.log(contract);
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'signed':
        return 'success';
      case 'pending':
        return 'warning';
      default:
        return 'default';
    }
  };
  const formatGenres = (genres) => {
    if (Array.isArray(genres)) {
      return genres.join(', ');
    } else if (typeof genres === 'string') {
      // If it's a string, split by potential separators and then join
      return genres.split(/[,;/]/).map(genre => genre.trim()).join(', ');
    }
    return genres; // fallback, just in case
  };

  const handleConfirmSign = () => {
    // Close the first dialog
    setOpenConfirmation(false);
    // Open the second dialog for sound check
    setOpenSoundCheckDialog(true);
  };

  /**
   * If the user hits "Cancel" on the first dialog
   */
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  /**
   * 2nd dialog -> "Pick your sound-check time"
   * After user picks an option, finalize the sign action
   */
  const handleConfirmSoundCheck = async () => {
    try {
      // ---- OPTIONAL: Here you can do some logic about the soundCheckOption or customSoundCheckTime. ----
      // For instance, you can compute or store the actual time. Example:
      let chosenTimeMessage = '';
      const eventStart = new Date(`2000-01-01T${contract.startTime}:00`);
      const selectedOption = dynamicSoundCheckOptions.find(
        (opt) => opt.value === soundCheckOption
      );
      
      if (!selectedOption) {
        chosenTimeMessage = 'No selection';
      } else if (selectedOption.value === 'custom') {
        // If user chose "Custom Time"
        chosenTimeMessage = customSoundCheckTime
          ? customSoundCheckTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
          : 'Not chosen';
      } else {
        // Otherwise subtract the selected minutes
        chosenTimeMessage = subtractMinutes(eventStart, selectedOption.minutesBefore);
      }

      // 1) Sign the contract
      const response = await axios.post(`${API_URL}/api/sign-contract`, {
        contractId: contract.id,
        sound_check: chosenTimeMessage
      });

      if (response.data.success) {
        // 2) Mark date as "booked"
        await axios.post(`${API_URL}/api/update-available-date`, {
          dateId: contract.booking_id,
          status: 'booked',
        });

        // 3) Send confirmation message to the artist
        await axios.post(`${API_URL}/api/send-message`, {
          receiverId: contract.other_party.id,
          content: `Your contract for the event on ${contract.date} has been signed. The agreed fee is ${contract.bid_amount}. 
          Sound check has been scheduled at: ${chosenTimeMessage}`,
        });

        // 4) Close the second dialog and show success
        setOpenSoundCheckDialog(false);
        setShowSuccess(true);

        // 5) Update local status
        onContractSigned(contract.id);
      }
    } catch (error) {
      console.error('Error signing contract:', error);
    }
  };

  // Helper to subtract X minutes from a Date
  const subtractMinutes = (date, minutes) => {
    const copy = new Date(date.getTime());
    copy.setMinutes(copy.getMinutes() - minutes);
    return copy.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          background:
            'linear-gradient(135deg, rgba(239, 97, 145, 0.05) 0%, rgba(255, 255, 255, 0.9) 100%)',
          padding: { xs: 2, sm: 4, md: 6 },
          position: 'relative',
          minHeight: '100vh',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '300px',
            background:
              'linear-gradient(180deg, rgba(239, 97, 145, 0.08) 0%, rgba(255, 255, 255, 0) 100%)',
            zIndex: 0,
          },
        }}
      >
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <Button
            onClick={onBack}
            variant="outlined"
            sx={{
              mb: 4,
              borderRadius: '12px',
              borderColor: '#EF6191',
              color: '#EF6191',
              '&:hover': {
                borderColor: '#FF8FB1',
                backgroundColor: 'rgba(239, 97, 145, 0.05)',
              },
            }}
          >
            Back to {page}
          </Button>

          <Paper
            elevation={0}
            sx={{
              borderRadius: '24px',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(20px)',
              border: '1px solid rgba(239, 97, 145, 0.1)',
              padding: { xs: 3, sm: 4, md: 6 },
              mb: 4,
              position: 'relative',
              boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '6px',
                background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                borderRadius: '24px 24px 0 0',
              },
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 800,
                    background: 'linear-gradient(135deg, #EF6191 0%, #FF8FB1 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: 2,
                  }}
                >
                  {contractDetails.artist}
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 3 }}>
                  {formatGenres(contractDetails.event)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}
              >
                <Chip
                  label={contractDetails.status}
                  color={getStatusColor(contractDetails.status)}
                  sx={{
                    fontSize: '1rem',
                    padding: '20px 16px',
                    borderRadius: '12px',
                    fontWeight: 600,
                    backgroundColor:
                      getStatusColor(contractDetails.status) === 'success' ? '#EF6191' : undefined,
                    '& .MuiChip-label': {
                      padding: '0 8px',
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} sx={{ mt: 2 }}>
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    height: '100%',
                    borderRadius: '16px',
                    backgroundColor: 'rgba(239, 97, 145, 0.05)',
                    border: '1px solid rgba(239, 97, 145, 0.1)',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                    Event Details
                  </Typography>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <Event sx={{ mr: 2, color: '#EF6191' }} />
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Date</Typography>}
                        secondary={contractDetails.date}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <Schedule sx={{ mr: 2, color: '#EF6191' }} />
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Duration</Typography>}
                        secondary={contractDetails.duration}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <Place sx={{ mr: 2, color: '#EF6191' }} />
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Venue</Typography>}
                        secondary={contractDetails.venue}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <AttachMoney sx={{ mr: 2, color: '#EF6191' }} />
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Fee</Typography>}
                        secondary={`$${contractDetails.fee}`}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    height: '100%',
                    borderRadius: '16px',
                    backgroundColor: 'rgba(239, 97, 145, 0.05)',
                    border: '1px solid rgba(239, 97, 145, 0.1)',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                    Performance Requirements
                  </Typography>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Equipment</Typography>}
                        secondary={contractDetails.specialRequirements || 'No equipment specified'}
                      />
                    </ListItem>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 600 }}>Special Requirements</Typography>}
                        secondary={contractDetails.equipment || 'No special requirements'}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  borderRadius: '16px',
                  backgroundColor: 'rgba(239, 97, 145, 0.05)',
                  border: '1px solid rgba(239, 97, 145, 0.1)',
                  mt: 4,
                }}
              >
                <Typography variant="h6" sx={{ mb: 3, color: '#EF6191', fontWeight: 600 }}>
                  Contract Terms
                </Typography>
                <List
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                >
                  {contractDetails.contractTerms.map((term, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        sx={{
                          px: 3,
                          py: 2,
                          '&:hover': {
                            backgroundColor: 'rgba(239, 97, 145, 0.03)',
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 500 }}>{term}</Typography>
                          }
                        />
                      </ListItem>
                      {index < contractDetails.contractTerms.length - 1 && (
                        <Divider
                          sx={{
                            borderColor: 'rgba(239, 97, 145, 0.1)',
                            margin: '0 24px',
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '12px 24px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                    transform: 'translateY(-1px)',
                  },
                }}
              >
                Edit Contract
              </Button>
              {isContractBooked ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleViewSignedCopy}
                  sx={{
                    backgroundColor: '#ccc',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      backgroundColor: '#bbb',
                      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.15)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  View Signed Copy
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSignContract}
                  sx={{
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    color: '#fff',
                    borderRadius: '12px',
                    padding: '12px 24px',
                    fontSize: '1rem',
                    fontWeight: 600,
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                    '&:hover': {
                      background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                      boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                      transform: 'translateY(-1px)',
                    },
                  }}
                >
                  Sign Contract
                </Button>
              )}
            </Box>
          </Paper>

          {/* 1) Confirmation Dialog */}
          <Dialog
            open={openConfirmation}
            onClose={handleCloseConfirmation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: '24px',
                padding: 2,
              },
            }}
          >
            <DialogTitle
              sx={{ color: '#EF6191', fontWeight: 600, backgroundColor: 'transparent !important' }}
            >
              Confirm Contract Signing
              <IconButton
                onClick={handleCloseConfirmation}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: '#EF6191',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" sx={{ mt: 2 }}>
                You are about to sign the contract for {contractDetails.artist} on{' '}
                {contractDetails.date} for ${contractDetails.fee}. Are you sure you want to proceed?
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: 3 }}>
              <Button
                onClick={handleCloseConfirmation}
                sx={{
                  color: '#EF6191',
                  borderColor: '#EF6191',
                  borderRadius: '12px',
                  padding: '8px 16px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(239, 97, 145, 0.1)',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmSign}
                variant="contained"
                sx={{
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '12px 24px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                    transform: 'translateY(-1px)',
                  },
                }}
              >
                Confirm and Proceed
              </Button>
            </DialogActions>
          </Dialog>

          {/* 2) Sound Check Dialog: triggered after confirming the first */}
          <Dialog
            open={openSoundCheckDialog}
            onClose={() => setOpenSoundCheckDialog(false)}
            aria-labelledby="sound-check-dialog-title"
            fullWidth
            maxWidth="sm"
            PaperProps={{
              sx: {
                borderRadius: '24px',
                p: 2,
              },
            }}
          >
            <DialogTitle sx={{ color: '#EF6191', fontWeight: 600, backgroundColor: 'transparent !important' }}>
              Select Sound Check Time
              <IconButton
                onClick={() => setOpenSoundCheckDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: '#EF6191',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography sx={{ mb: 2 }}>
                Please choose how long before the performance you’d like the sound check to start.
              </Typography>

              <FormControl component="fieldset" sx={{ width: '100%' }}>
                <FormLabel component="legend" sx={{ color: '#EF6191', fontWeight: 600 }}>
                  Sound Check
                </FormLabel>
                <RadioGroup
                  name="soundCheckOption"
                  value={soundCheckOption}
                  onChange={(e) => setSoundCheckOption(e.target.value)}
                >
                 {dynamicSoundCheckOptions.map((opt) => (
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={
                        <Radio
                          sx={{
                            color: '#EF6191',
                            '&.Mui-checked': { color: '#EF6191' },
                          }}
                        />
                      }
                      label={opt.label} // e.g. "7:30 PM", "7:00 PM", "Custom Time"
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              {/* If custom is chosen, show a time picker */}
              {soundCheckOption === 'custom' && (
                <Box sx={{ mt: 3 }}>
                  <TimePicker
                    label="Custom Sound Check Time"
                    value={customSoundCheckTime}
                    onChange={(newValue) => setCustomSoundCheckTime(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#EF6191',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#EF6191',
                            borderWidth: '2px',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button
                onClick={() => setOpenSoundCheckDialog(false)}
                sx={{
                  color: '#EF6191',
                  borderColor: '#EF6191',
                  borderRadius: '12px',
                  padding: '8px 16px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'rgba(239, 97, 145, 0.1)',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmSoundCheck}
                variant="contained"
                sx={{
                  background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                  color: '#fff',
                  borderRadius: '12px',
                  padding: '12px 24px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #EF6191 0%, #FF8FB1 100%)',
                    boxShadow: '0 6px 16px rgba(239, 97, 145, 0.4)',
                    transform: 'translateY(-1px)',
                  },
                }}
              >
                Confirm & Sign
              </Button>
            </DialogActions>
          </Dialog>

          {/* Signed Contract Dialog */}
          <Dialog
            open={openSignedContract}
            onClose={handleCloseSignedCopy}
            fullWidth
            maxWidth="md"
            PaperProps={{
              sx: {
                borderRadius: '24px',
                padding: 2,
              },
            }}
            aria-labelledby="signed-contract-dialog-title"
          >
            <DialogTitle sx={{ color: '#EF6191', fontWeight: 600 }}>
              Signed Contract
              <IconButton
                onClick={handleCloseSignedCopy}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  color: '#EF6191',
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    Artist: {contractDetails.artist}
                    <CheckCircle color="success" sx={{ ml: 1 }} />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">
                    Venue: {contractDetails.venue}
                    <CheckCircle color="success" sx={{ ml: 1 }} />
                  </Typography>
                </Grid>
              </Grid>

              <Paper
                elevation={0}
                sx={{ p: 3, mb: 2, borderRadius: '16px', backgroundColor: 'rgba(239, 97, 145, 0.05)' }}
              >
                <Typography variant="h6" sx={{ mb: 2, color: '#EF6191', fontWeight: 600 }}>
                  Event Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>Date: {contractDetails.date}</Typography>
                    <Typography>Duration: {contractDetails.duration}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Venue: {contractDetails.venue}</Typography>
                    <Typography>Fee: ${contractDetails.fee}</Typography>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                elevation={0}
                sx={{ p: 3, mb: 2, borderRadius: '16px', backgroundColor: 'rgba(239, 97, 145, 0.05)' }}
              >
                <Typography variant="h6" sx={{ mb: 2, color: '#EF6191', fontWeight: 600 }}>
                  Performance Requirements
                </Typography>
                <Typography>Equipment: {contractDetails.equipment}</Typography>
                <Typography>
                  Special Requirements: {contractDetails.specialRequirements}
                </Typography>
              </Paper>

              <Paper
                elevation={0}
                sx={{ p: 3, borderRadius: '16px', backgroundColor: 'rgba(239, 97, 145, 0.05)' }}
              >
                <Typography variant="h6" sx={{ mb: 2, color: '#EF6191', fontWeight: 600 }}>
                  Contract Terms
                </Typography>
                <List>
                  {contractDetails.contractTerms.map((term, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={<Typography sx={{ fontWeight: 500 }}>{term}</Typography>}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </DialogContent>
          </Dialog>

          {/* Success Snackbar */}
          <Snackbar
            open={showSuccess}
            autoHideDuration={6000}
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSuccess}
              severity="success"
              sx={{
                minWidth: '300px',
                borderRadius: '16px',
                backgroundColor: '#EF6191',
                color: '#fff',
                boxShadow: '0 4px 12px rgba(239, 97, 145, 0.3)',
                '& .MuiAlert-icon': {
                  color: '#fff',
                },
                '& .MuiAlert-action': {
                  color: '#fff',
                },
              }}
              icon={<MusicNote sx={{ fontSize: '24px' }} />}
            >
              <Box>
                <Typography sx={{ fontWeight: 600, mb: 0.5 }}>Contract Signed!</Typography>
                <Typography variant="body2">
                  Contract successfully signed! A confirmation has been sent to the artist.
                </Typography>
              </Box>
            </Alert>
          </Snackbar>
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

export default ContractDashboard;