import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const PageContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 4rem 2rem;
  min-height: 100vh;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
      radial-gradient(circle at 20% 30%, rgba(239, 97, 145, 0.05) 0%, transparent 50%),
      radial-gradient(circle at 80% 70%, rgba(41, 98, 255, 0.05) 0%, transparent 50%);
    animation: rotateBG 30s linear infinite;
    z-index: 0;
  }

  @keyframes rotateBG {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 2rem;
  background: linear-gradient(135deg, #EF6191 0%, #2962ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Section = styled(motion.section)`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 2rem;
  margin: 2rem 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  
  h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #EF6191;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: rgba(28, 32, 40, 0.8);
    margin-bottom: 1.5rem;
    font-family: 'Poppins', sans-serif;
  }
`;

const HighlightBox = styled(motion.div)`
  background: linear-gradient(135deg, rgba(239, 97, 145, 0.1), rgba(41, 98, 255, 0.1));
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1rem 0;
  border: 1px solid rgba(239, 97, 145, 0.2);
  
  strong {
    color: #2962ff;
  }
`;

const AboutUsPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6
      }
    }
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Title
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            About Book-Up
          </Title>

          <Section variants={sectionVariants}>
            <h2>Introduction</h2>
            <p>
              The current system for artists looking to pursue their passion is inefficient and often discouraging. 
              Talented musicians find themselves spending more time acting as agents and businessmen rather than 
              focusing on their art.
            </p>
            <HighlightBox
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <p>
                <strong>At Book-Up, we believe that artists should be free to create</strong>, not bogged down by 
                the complexities of booking gigs. Our platform is designed to give artists a sense of security 
                and the freedom to pursue their creative dreams.
              </p>
            </HighlightBox>
          </Section>

          <Section variants={sectionVariants}>
            <h2>Mission Statement</h2>
            <p>
              Book-Up's mission is to revolutionize and automate the booking process for artists and venues, 
              making it more efficient and equitable. We aim to eliminate the disparity in success caused by 
              monetary discretion, ensuring that all artists have equal opportunities to perform and succeed.
            </p>
            <HighlightBox
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <p>
                <strong>We strive to streamline the process for both artists and venues</strong>, providing easy 
                access to a diverse pool of talented performers while simplifying the booking experience.
              </p>
            </HighlightBox>
          </Section>

          <Section variants={sectionVariants}>
            <h2>Vision</h2>
            <p>
              We envision a world where artists can pursue their careers without the hindrance of acting as 
              their own agents. Book-Up seeks to level the playing field, providing every artist with the tools 
              they need to find the right venues that match their time, place, genre, and pay preferences.
            </p>
            <HighlightBox
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <p>
                <strong>Success should be based on talent and passion</strong>, not on financial capabilities 
                or business acumen.
              </p>
            </HighlightBox>
          </Section>

          <Section variants={sectionVariants}>
            <h2>Our Approach</h2>
            <p>
              As passionate live music enthusiasts, our best interests align with creating an efficient gig 
              booking platform. Book-Up utilizes an advanced algorithm to match artists and venues based on 
              aligned visions and preferences.
            </p>
            <HighlightBox
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <p>
                <strong>Our platform ensures that artists find the perfect fit</strong> for their performances 
                and venues discover the best talent to entertain their audiences.
              </p>
            </HighlightBox>
          </Section>
        </motion.div>
      </ContentWrapper>
    </PageContainer>
  );
};

export default AboutUsPage;