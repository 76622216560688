import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Badge, Tooltip, Button, Dialog, DialogTitle, DialogContent, DialogActions, Box, TextField, Grid, Stack
} from '@mui/material';
import axios from 'axios';
import API_URL from '../config/apiUrl';
const FinancialDataCard = ({ stripeNum, profileType }) => {
    const [open, setOpen] = useState(false);
    const [openBank, setOpenBank] = useState(false);
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [selectedDesc, setSelectedDesc] = useState(null);
    const [selectedID, setSelectedID] = useState(null);
    const [payment1, setPayment1] = useState(null);
    const [payment2, setPayment2] = useState(null);
    const [customer, setCustomer] = useState({
        address: {
            city: '',
            country: '',
            line1: '',
            line2: '',
            postal_code: '',
            state: '',
        },
        bankName: '',
        email: '',
        last4: '',
        name: '',
        phone: '',
        status: '',
        transaction: {
            description: '',
            amount: '',
            status: ''
        },
        count: 0,
        money: 0.0,
        avg: 0
    });

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/api/customer/finances/${profileType}`
                );
                const transformedCustomer = {
                    address: {
                        city: response.data.address.city,
                        country: response.data.address.country,
                        line1: response.data.address.line1,
                        line2: response.data.address.line2 || null,
                        postal_code: response.data.address.postal_code,
                        state: response.data.address.state,
                    },
                    bankName: response.data.bankName,
                    email: response.data.email,
                    last4: response.data.last4,
                    name: response.data.name,
                    phone: response.data.phone,
                    status: response.data.status,
                    transactions: response.data.transactions.map(transaction => ({
                        description: transaction.description,
                        amount: transaction.amount,
                        status: transaction.status,
                        id: transaction.id,
                        created: transaction.created,
                        eventDate: transaction.eventDate,
                        name: transaction.name
                    })),
                    count: response.data.count,
                    money: response.data.money,
                    avg: response.data.avg
                };

                setCustomer(transformedCustomer);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching customer data:', error);
            }

        };

        fetchCustomerData();

    }, [stripeNum]);

    const handleOpen = (amount, desc, id) => {
        setOpen(true);
        setSelectedAmount(amount);
        setSelectedDesc(desc);
        setSelectedID(id);
    };
    const handleOpenBank = () => {
        setOpenBank(true)
    }

    const handleSubmit = async () => {
        let data
        try {
            const response = await fetch("https://api.ipify.org?format=json");
            data = await response.json();
        } catch (error) {
            console.error("Failed to fetch IP address:", error);
        }

        const userAgent = navigator.userAgent;

        axios.post(`${API_URL}/api/confirmation/payment`, {
            PIN: selectedID,
            ip: data.ip,
            ua: userAgent,
            amount: parseInt(Math.round(payment2 * 100))
        })
        setPayment2(null);
        setOpen(false);
    };

    const handleBankSubmit = async () => {
        axios.post(`${API_URL}/api/confirmation/bank/account`, {
            payment1: payment1,
        })
        setOpenBank(false)
    }

    const handleClose= ()  => {
        setOpen(false);
        setOpenBank(false);
    };

    return customer?.name ? (
        <Card sx={{ p: 4, boxShadow: 3, borderRadius: 2, maxWidth: '100%', overflowX: 'auto' }}>
            <CardHeader
                title="Customer Financial Data"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold', textAlign: 'center' }}
                sx={{ backgroundColor: '#f5f5f5', mb: 2 }}
            />
            <CardContent>
                <section style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '16px', marginBottom: '16px' }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Account Information
                    </Typography>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Total Performances</TableCell>
                                {profileType === 'vendor' ? (
                                    <TableCell>Total Spending</TableCell>
                                ) : (<TableCell>Total Earnings</TableCell>)}
                                <TableCell>Average Price</TableCell>
                                <TableCell>Address</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{textAlign:'left'}}>{customer.name}</TableCell>
                                <TableCell>{customer.count}</TableCell>
                                <TableCell>${customer.money}</TableCell>
                                <TableCell>${customer.avg}</TableCell>
                                <TableCell>
                                    {customer.address.line1} {customer.address.city} {customer.address.state} {customer.address.postal_code}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </section>

                {/* Payment Method Section */}
                <section style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '16px', marginBottom: '16px' }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Payment Method
                    </Typography>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Bank</TableCell>
                                <TableCell>Last 4 digits of account</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{textAlign:'left'}}>{customer.name}</TableCell>
                                <TableCell>{customer.bankName}</TableCell>
                                <TableCell>{customer.last4}</TableCell>
                                <TableCell>
                                    { (typeof customer.status === 'boolean') && (
                                        <Tooltip title={customer.status === true ? "Account ready to use" : "Please confirm account"}>
                                            { customer.status === true ? (
                                                <Button variant="contained" color="success" style={{width: "100%"}}>
                                                    Approved
                                                </Button>
                                            ) : (
                                                <Button variant="outlined" color="error" onClick={() => handleOpenBank()} style={{width: "100%"}}>
                                                    Awaiting
                                                </Button>
                                            )}
                                        </Tooltip>
                                    )}
                                    { (typeof customer.status !== 'boolean') && (
                                        <Tooltip title={customer.status === "succeeded" ? "Account ready to use" : "Please confirm account"}>
                                            { customer.status === "succeeded" ? (
                                                <Button variant="contained" color="success" style={{width: "100%"}}>
                                                    Approved
                                                </Button>
                                            ) : (
                                                <Button variant="outlined" color="error" onClick={() => handleOpenBank()} style={{width: "100%"}}>
                                                    Awaiting
                                                </Button>
                                            )}
                                        </Tooltip>
                                    )}

                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </section>

                {/* Transactions Section */}
                <section>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                        Transactions
                    </Typography>
                    {profileType === 'performer' && (
                        <Typography variant="body1" gutterBottom>
                            Transactions will appear when venue approves the payment, please allow up to 10 days to see funds in your bank account.
                        </Typography>
                    )}
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
                            <TableRow>
                                {profileType === 'vendor' && (
                                    <>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Performance Date</TableCell>
                                    </>
                                )}
                                {profileType === 'performer' && (
                                    <>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Transfer Date</TableCell>
                                        <TableCell>Performance Date</TableCell>
                                    </>
                                )}
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { profileType === 'vendor' && customer.transactions && customer.transactions.length > 0 ? (
                                customer.transactions.map((transaction, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{textAlign:'left'}}>
                                            {transaction.description.split(" is ")[0]}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.description.split(" on ")[1]}
                                        </TableCell>
                                        <TableCell>$ -{(transaction.amount / 100).toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Tooltip title={transaction.status === "succeeded" ? "Payment succeeded" : "Money will not move until confirmation"}>
                                                { transaction.status === "succeeded" ? (
                                                    <Button variant="contained" color="success" style={{width: "100%"}}>
                                                        Approved
                                                    </Button>
                                                ) : (
                                                    <Button variant="outlined" color="error" onClick={() => handleOpen(transaction.amount, transaction.description, transaction.id)} style={{width: "100%"}}>
                                                        Awaiting
                                                    </Button>
                                                )}
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                customer.transactions.map((transaction, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{textAlign:'left'}}>
                                            {transaction.description}
                                        </TableCell>
                                        <TableCell>
                                            {new Date(transaction.created * 1000).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {transaction.eventDate}
                                        </TableCell>
                                        <TableCell>$ {(transaction.amount / 100).toFixed(2)}</TableCell>
                                        <TableCell>
                                            {transaction.status}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </section>
            </CardContent>
            { profileType === 'vendor' && (
                <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                    <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Customer Details
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ textAlign: 'center', padding: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                Ready to confirm payment?
                            </Typography>
                            <Typography variant="h9" color="text.disabled" gutterBottom>
                                Confirming is not require until after event
                            </Typography>
                            <Box>
                                <Typography variant="body1" color="text.secondary" gutterBottom>
                                    {selectedDesc}
                                </Typography>
                                <Typography variant="h6" color="primary">
                                    Total: ${(Number(selectedAmount) / 100).toFixed(2)}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h5" color="text.secondary" gutterBottom>
                                    Would you like to add a tip?
                                </Typography>
                                <Box sx={{ textAlign: 'center', padding: 2 }}>
                                    <TextField
                                        label="Tip amout"
                                        variant="outlined"
                                        type='number'
                                        fullWidth
                                        onChange={(e) => setPayment2(e.target.value)}
                                        margin="normal"
                                        placeholder="10.20"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            Confirm
                        </Button>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            { profileType === 'vendor' && (
                <Dialog open={openBank} onClose={handleClose} maxWidth="sm" fullWidth>
                    <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Customer Details
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ textAlign: 'center', padding: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                Ready to confirm payment method?
                            </Typography>
                            <Typography variant="h9" color="text.disabled" gutterBottom>
                                Stripe sent a small deposit to this bank account. To verify this account, please confirm the 6-digit code in the statement descriptor of this deposit.
                            </Typography>
                        </Box>
                        <Box sx={{ textAlign: 'center', padding: 2 }}>
                            <TextField
                                label="Deposit Code"
                                variant="outlined"
                                fullWidth
                                value={payment1}
                                onChange={(e) => setPayment1(e.target.value)}
                                margin="normal"
                                placeholder="SM11AA"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
                        <Button onClick={handleBankSubmit} color="primary" variant="contained">
                            Confirm
                        </Button>
                        <Button onClick={handleClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Card>
    ) : (
        <h1>Loading...</h1>
    )
};

export default FinancialDataCard;
