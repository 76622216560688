import React, { useContext } from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { DataContext } from './DataContext';  // Adjust this import path if necessary
import CategoryPreview from './CategoryPreview';
import VendorMap from './Map';
import ClearDBButton from './killbutton';
import Feed from './Feed';
import './Home.css';
import Particles from 'react-tsparticles';
import styled from 'styled-components';
import UnifiedHome from './UnifiedContainer';

const HomeContainer = styled.div`
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(239, 97, 145, 0.05), transparent 70%);
    animation: rotateBG 30s linear infinite;
    z-index:0;
  }

  @keyframes rotateBG {
    from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
  }
`;

const SectionContainer = styled.section`
  padding: 3rem 2rem;
  position: relative;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  overflow: hidden;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 992px) {
    flex-direction: column;
  }

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at center, rgba(239, 97, 145, 0.05), transparent 70%);
    animation: rotateBG 30s linear infinite;
    z-index: -1;
  }

  @keyframes rotateBG {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const HeroImageWrapper = styled.div`
  flex: 1;
  max-width: 45%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transform: translateY(-10px);
  transform-style: preserve-3d;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px) scale(1.02);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(239, 97, 145, 0.4), rgba(41, 98, 255, 0.4));
    mix-blend-mode: multiply;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  max-width: 45%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
   transform: translateY(10%);
  transform-style: preserve-3d;
  
  &:hover {
    transform: scale(1.02);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(239, 97, 145, 0.4), rgba(41, 98, 255, 0.4));
    mix-blend-mode: multiply;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const TextContent = styled.div`
  flex: 2;
  max-width: 600%;

  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(135deg, #EF6191 0%, #2962ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
    position: relative;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.8;
    color: rgba(28, 32, 40, 0.8);
    background: rgba(255, 255, 255, 0.8);
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
  }

  ul {
    padding-left: 1.5rem;
    margin-top: 1rem;
    list-style-type: disc;
  }

  li {
    margin-bottom: 0.5rem;
  }

  strong {
    color: #EF6191;
    font-weight: bold;
  }
`;

function Home() {
    const { user, performers, venues } = useContext(DataContext);
    console.log(user);


    const sections = [
        {
            title: "About Us",
            description:
                `
                  We are a the ultimate end-to-end platform designed to <strong>revolutionize the live 
                  music booking process for both artists and venues.</strong> 
                  Offering a seamless, all-in-one solution, Bookup streamlines every aspect of booking, 
                  from connecting artists with venues to creating legally binding contracts for every gig.
                  Our intuitive platform handles everything, including 
                  <strong>communication, contracts, and logistics, ensuring a professional and efficient booking experience. </strong>
                  Whether you're a venue looking to keep your stage vibrant or an artist seeking consistent gigs, 
                  Bookup is the all-in-one platform you can <strong>trust to connect, simplify, 
                  and elevate your live music journey.</strong>
            `,
            image: 'https://images.pexels.com/photos/2114365/pexels-photo-2114365.jpeg',
            filterColor: 'pink',
        },
        {
            title: "For The Venues",
            description:
                `
                  Can save valuable time by leveraging automated matchmaking that aligns with their specific needs—whether it’s genre, audience size, or technical requirements.
                   Venues often face challenges finding the right talent for their events. 
                  Our platform provides an <strong>easy-to-use marketplace</strong> where 
                  venues can:
                  <ul>
                    <li><strong>Post available dates and event details</strong>, specifying requirements for artists.</li>
                    <li><strong>Browse and evaluate bids</strong> from a wide range of talented artists, ensuring they find the perfect match for their audience.</li>
                    <li>Use <strong>messaging tools</strong> to communicate directly with artists, discuss details, and finalize bookings with ease.</li>
                  </ul>
                  By using our platform, venues can <strong>save time</strong>, 
                  <strong>reduce administrative work</strong>, and focus on delivering 
                  exceptional events. Whether you're managing a small local bar or a large 
                  music venue, we make it simple to discover artists and book performances 
                  efficiently.
                `,
            image: 'https://media.istockphoto.com/id/184939584/photo/a-nightclub-with-different-color-lights.jpg?s=612x612&w=0&k=20&c=suLq79tWm8xScx6Zi7qeEryOMAxKtW5gpZNjIgB97T0=',
            filterColor: 'orange',
        },
        {
            title: "For The Artists",
            description:
                `
                  Bookup eliminates the hassle of cold calls and hours spent networking, providing instant access to 
                  venue opportunities tailored to their genre, experience, and preferences. Our platform allows artists to:
                  <ul>
                    <li><strong>Discover venues and events</strong> that align with their style and schedule.</li>
                    <li><strong>Bid on available dates</strong>, presenting their talents to venue managers and showcasing why they are the best choice.</li>
                    <li>Communicate directly with venues through our <strong>messaging feature</strong>, allowing for transparent discussions and relationship building.</li>
                  </ul>
                  Whether you're an emerging artist looking for your first gig or a 
                  seasoned performer seeking new opportunities, our platform is designed 
                  to <strong>open doors</strong> and help you grow your career.
                `,
            image: 'https://media.istockphoto.com/id/1464613358/photo/view-of-band-performing-to-audience-from-back-of-stage.jpg?s=612x612&w=0&k=20&c=VPoK_4x9QOemGF8WZLoJEQnBAhiqhGlJHPLikbPyf7o=',
            filterColor: 'purple',
        },
    ];
    
    return (
        <UnifiedHome>

        <HomeContainer>
          

          <Feed />
          <br/>
          {sections.map((section, index) => (
            <SectionContainer key={index} reverse={index % 2 !== 0}>
              {index === 0 ? (
                <HeroImageWrapper>
                  <img src={section.image} alt={section.title} />
                </HeroImageWrapper>
              ) : (
                <ImageWrapper>
                  <img src={section.image} alt={section.title} />
                </ImageWrapper>
              )}
              <TextContent>
                <h2>{section.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: section.description }} />
              </TextContent>
            </SectionContainer>
          ))}
          <br/>
          <br/>
          <br/>
                <br/>
          {user ? (
            user.type === 'vendor' ? (
              <CategoryPreview title="Available Performers" items={performers} />
            ) : (
              <CategoryPreview title="Top Venues" items={venues} />
            )
          ) : (
            <>
            
            </>
          )}
          <VendorMap />
        </HomeContainer>
        </UnifiedHome>
      );
    }
    
    export default Home;