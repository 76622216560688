import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BankingProfileForm from './stripeCreate';
import VendorFinances from './vendorFinances'
import API_URL from '../config/apiUrl';

function App() {
    const [loading, setLoading] = useState(true);
    const [profileType, setProfileType] = useState(null);
    const [stripeNum, setStripeNum] = useState(null);

    useEffect(() => {
        const fetchFinances = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/finances`);
                setProfileType(response.data.user_type);
                setStripeNum(response.data.stripeID);
                console.log(response)
                if (!response.data.stripeID) {
                    alert("No Financial Information Found, Please follow form to set up.")
                }
            } catch (error) {
                console.error('Error fetching finances:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFinances();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!stripeNum) {
        return (
            <div style={{alignItems: "center"}}>
                <BankingProfileForm profileType={profileType}/>
            </div>
        );
    }

    // Render finances if available
    return (
        <div>
            { profileType === 'vendor' && (
                <VendorFinances stripeNum={stripeNum} profileType={profileType} />
            )}
            { profileType === 'performer' && (
                <VendorFinances stripeNum={stripeNum} profileType={profileType} />
            )}
        </div>
    );
}

export default App;
