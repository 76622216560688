import React, { useContext, useEffect, useState } from 'react';
import { 
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Container,
  IconButton,
  styled,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Divider,
  Alert,
  Button,
} from '@mui/material';
import { useUser } from '../context/UserContext';
import { Link, useParams } from 'react-router-dom';
import { DataContext } from './DataContext';  // Adjust this import path if necessary
import CategoryPreview from './CategoryPreview';
import { CalendarToday, NotificationsNone, Message, Person } from '@mui/icons-material';
import EnhancedArtistCard from './TopMatchedArtistCard';
import API_URL from '../config/apiUrl';
import axios from 'axios';
import RecentDateMatches from './RecentDateMatches';
// Create a custom theme with your brand color
const theme = createTheme({
  palette: {
    primary: {
      main: '#EF6191',
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
    },
  },
});

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
}));

const QuickActionCard = styled(Card)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    backgroundColor: '#f5f5f7',
    '&:hover': {
      backgroundColor: '#e8e8e8',
      cursor: 'pointer',  // Add cursor pointer to show it's clickable
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primary.main,
    },
  }));
  
  const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
  });

const BrandedTypography = styled(Typography)(({ theme }) => ({
  '&.brand-heading': {
    color: theme.palette.primary.main,
  },
}));

const VenueLandingPage = () => { 
    const { id } = useParams(); 
  const { realUser, logout } = useUser();
  const { user, performers, venues } = useContext(DataContext);

  const [recentMatches, setRecentMatches] = useState([]);
  const [recentDate, setRecentDate] = useState(null);


  console.log(id);
  const products = [
    { name: 'Pop', description: 'Discover and Pop artists', price: '$500 - $2000' },
    { name: 'Rock', description: 'Hire bands for your events', price: '$800 - $3000' },
    { name: 'DJ', description: 'Find the best DJs for any party', price: '$300 - $1500' },
    { name: 'Band', description: 'Bring laughter to your audience', price: '$400 - $1200' },
  ];

  
  const quickActions = [
    { 
        icon: <CalendarToday />, 
        title: 'Create a Booking', 
        description: 'Start your next event booking',
        path: `/fordurk/${id}`  // Just use id
    },
    { 
      icon: <NotificationsNone />, 
      title: 'Active Bids', 
      description: 'View your current bookings',
      path: '/VendorImpBooking/:profile_id?page=activeBiddings'
    },
    { 
      icon: <Message />, 
      title: 'Messages', 
      description: 'Chat with talent',
      path: '/messages/:profile_id'  // Path to messages page
    },
    { 
      icon: <Person />, 
      title: 'Profile', 
      description: 'Manage your account',
      path: '/user/profile'
    },
  ];

  const [matchedArtists, setMatchedArtists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const artistsPerPage = 3;
  const matchesPerPage = 3;

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/matches`);
        if (response.data) {
          setMatchedArtists(response.data.general_matches);
          setRecentMatches(response.data.recent_date_matches);
          setRecentDate(response.data.recent_date);
          console.log('HEHEHEHEHEHEHHE',response.data);
          console.log(response.data.recent_date_matches);
        } else {
          setError('No matches found.');
        }
      } catch (err) {
        console.error(err);
        setError('Failed to fetch matches.');
      } finally {
        setLoading(false);
      }
    };

    fetchMatches();
  }, [id]);

  const topMatchedArtist = matchedArtists[0];
  console.log("matched artist", matchedArtists);
  console.log("matched artist", topMatchedArtist);
  const totalPages = Math.ceil(matchedArtists.length / artistsPerPage);

  // Get current page artists
  const indexOfLastArtist = currentPage * artistsPerPage;
  const indexOfFirstArtist = indexOfLastArtist - artistsPerPage;
  const currentArtists = matchedArtists.slice(indexOfFirstArtist, indexOfLastArtist);

  // Handlers for pagination
  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const indexOfLastMatch = currentPage * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = recentMatches.slice(indexOfFirstMatch, indexOfLastMatch);
  // Reset to first page when matchedArtists changes
  useEffect(() => {
    setCurrentPage(1);
  }, [recentMatches]);


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        {/* Header Section */}
        <Box sx={{ mb: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <BrandedTypography variant="h3" component="h1" className="brand-heading" sx={{ fontWeight: 600 }}>
                Book-Up.
              </BrandedTypography>
              <Typography variant="h3" sx={{ color: 'text.secondary', fontWeight: 600, mt: 2 }}>
                The easy way to book talent.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Feature card for top matched artist */}
              {matchedArtists.length > 0 && (
                <EnhancedArtistCard 
                  artist={matchedArtists[0]} 
                  isFeatureCard={true}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mb: 8 }}>
          <Grid container spacing={3}>
            {products.map((product, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledLink 
                    to={
                      product.name === "DJ" || product.name === "Band"
                        ? `/search?type=type&typeQuery=${encodeURIComponent(product.name)}`
                        : `/search?type=genre&genreQuery=${encodeURIComponent(product.name)}` 
                    }
                  >
                  <StyledCard>
                    <CardContent>
                      <BrandedTypography variant="h6" className="brand-heading" sx={{ mb: 1 }}>
                        {product.name}
                      </BrandedTypography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {product.description}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {product.price}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </StyledLink>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Quick Actions Section */}
        <Box sx={{ mb: 8 }}>
          <BrandedTypography variant="h4" className="brand-heading" sx={{ mb: 1 }}>
            Quick actions.
          </BrandedTypography>
          <Typography variant="h5" sx={{ color: 'text.secondary', mb: 4 }}>
            Manage your bookings and communications.
          </Typography>

          <Grid container spacing={3}>
            {quickActions.map((action, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledLink to={action.path}>
                  <QuickActionCard>
                    <CardContent>
                      <IconButton sx={{ mb: 2, p: 0 }}>
                        {action.icon}
                      </IconButton>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {action.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {action.description}
                      </Typography>
                    </CardContent>
                  </QuickActionCard>
                </StyledLink>
              </Grid>
            ))}
          </Grid>
        </Box>
        

        {/* **Add Divider Here
        <Box sx={{ my: 8 }}>
          <Divider 
            sx={{ 
              borderColor: 'grey.300', 
              height: '1px', 
              width: '100%', 
            }} 
          />
        </Box>
        ** **/}
        <Box sx={{ mb: 8 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>

            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ color: 'text.secondary', fontWeight: 600, mt: 2 }}>
                Find great performers
              </Typography>
              <BrandedTypography variant="h3" component="h1" className="brand-heading" sx={{ fontWeight: 600 }}>
                Live and Local.
              </BrandedTypography>
            </Grid>
          </Grid>
        </Box>

        {/* Top Matched Artists Section */}
        {/* <Box sx={{ mb: 8 }}>
          <BrandedTypography variant="h4" className="brand-heading" sx={{ mb: 2 }}>
            Top Matched Artists
          </BrandedTypography>

          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 4 }}>
              {error}
            </Alert>
          )} 

           {/* {!loading && !error && matchedArtists.length > 0 && (
            <>
              <Grid container spacing={4}>
                {currentArtists.map((artist) => (
                  <Grid item xs={12} sm={6} md={4} key={artist.id}>
                    <EnhancedArtistCard artist={artist} />
                  </Grid>
                ))}
              </Grid>

              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center', 
                  mt: 4,
                  gap: 2
                }}
              >
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handlePrevPage} 
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>

                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleNextPage} 
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </Box>
            </>
          )}

          {!loading && !error && matchedArtists.length === 0 && (
            <Typography variant="body1">No matched artists found.</Typography>
          )}  
        </Box> */}
         <Box sx={{ mb: 8 }}>
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
        Matches for {new Date(recentDate).toLocaleDateString()}
      </Typography>

      {recentMatches.length > 0 ? (
        <>
          <Grid container spacing={4}>
            {currentMatches.map((match) => (
              <Grid item xs={12} sm={6} md={4} key={match.id}>
                <EnhancedArtistCard artist={match} />
              </Grid>
            ))}
          </Grid>

          {totalPages > 1 && (
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                mt: 4,
                gap: 2
              }}
            >
              {currentPage > 1 && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handlePrevPage}
                >
                  Previous
                </Button>
              )}

              {currentPage < totalPages && (
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              )}
            </Box>
          )}
        </>
      ) : (
        <Typography variant="body1">No matches found for this date.</Typography>
      )}
    </Box>

        <CategoryPreview title="Available Performers" items={performers} />

        {/* <Box sx={{ 
          borderTop: 1, 
          borderColor: 'divider',
          pt: 3,
          display: 'flex',
          justifyContent: 'space-between',
          mt: 8,
        }}>
          <Typography variant="body2" color="text.secondary">
            © 2024 Live & Local
          </Typography>
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
              Terms of Service
            </Typography>
            <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
              Contact Us
            </Typography>
            <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
              About Us
            </Typography>
          </Box>
        </Box> */}
      </Container>
    </ThemeProvider>
  );
};

export default VenueLandingPage;